import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { PayPalButton } from "react-paypal-button-v2";
import audio0 from './audio/audio0.mp3';
import video0 from './video/video0.mp4';
import axios from 'axios';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import {isSafari, isMobile} from "react-device-detect";
import DateTimePicker from 'react-datetime-picker';
import './index.css';
const url = 'https://www.f1lthyware.com/api/server.php';
// const url = 'http://localhost:8888/root/F1LTHY/public/api/server.php';
var shippingdata = null;
var discount = null;
var discounts = null;
const pages = ['SOCIALS', 'SHOP', 'MUSIC'];
var flicker = '';
class Audio extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.mute = this.mute.bind(this);
        this.playpause = this.playpause.bind(this);
    }
    mute() {
        const songs = document.getElementsByClassName('AudioPlayer');
        for (var i = 0; i < songs.length; i++) {
            if (this.props.muted === false) {
                songs[i].muted = true;
            }
            else {
                songs[i].muted = false;
            }
        }
    }
    playpause(song) {
        if (this.props.muted === false) {
            const songs = document.getElementsByClassName('AudioPlayer');
            for (var i = 0; i < songs.length; i++) {
                if (i !== song) {
                    songs[i].pause();
                }
                else {
                    songs[i].play();
                }
            }
        }
    }
    handleClick() {
        if (this.props.muted === false) {
            this.props.handleMute(true);
        }
        else {
            this.props.handleMute(false);
        }
        this.mute();
    }
    render() {
        switch (this.props.page) {
            case 'SELECT':     this.playpause(0); break;
            case 'SOCIALS':   this.playpause(0); break;
            case 'SHOP':   this.playpause(0); break;
            case 'CART':   this.playpause(0); break;
            case 'MUSIC':   this.playpause(0); break;
            default: this.playpause(5); break;
        } 
        var audio = classNames({
            'Audio': true,
            'row': true,
            'relative': true,
            'start': true,
        })
        var mute = classNames({
            'Mute': true,
            't2': true,
            'click': true,
            'quick': true,
            'scale': true,
            'textcenter': true,
            'Muted': this.props.muted === true,
            'Unmuted': this.props.muted === false,
        }) 
        const text = this.props.muted === true ? 'Unmute' : 'Mute'
        return (
            <div className = {audio}>
                <div className = {mute} onClick = {this.handleClick}>
                    {text}
                </div>
                <audio className = 'AudioPlayer' loop>
                    <source src = {audio0}></source>
                </audio>
            </div>
    )
  }
}
class Countdown extends React.Component {
    constructor(props) {
        super(props);
        this.updateCountdown = this.updateCountdown.bind(this);
        this.getDateDifference = this.getDateDifference.bind(this);
        this.currentDate = new Date();
        this.state = {
            days: null,
            hours: null,
            minutes: null,
            seconds: null
        }
    }
    updateCountdown() {
        if (this.state.days === 0 && this.state.hours === 0 && this.state.minutes === 0 && this.state.seconds === 0) {
            clearInterval(this.interval);
            window.location.reload(false);
        }
        else {
            this.currentDate = new Date();
            this.getDateDifference();
        }
    }
    getDateDifference() {
        var diff = Math.abs(this.props.lockdate - this.currentDate);
        var seconds = Math.floor(diff / (1000));
        var minutes = Math.floor(seconds / (60));
        var hours = Math.floor(minutes / (60));
        var days = Math.floor(hours / (24));
        hours %= 24;
        minutes %= 60;
        seconds %= 60;
        this.setState({
            days: days,
            hours: hours,
            minutes: minutes,
            seconds: seconds
        })
    }
    componentDidMount() {
        this.updateCountdown();
        this.interval = setInterval(() => {
            this.updateCountdown();
        }, 1000);
    }
    componentWillUnmount() {
        clearInterval(this.interval);
    }
    render() {
        var countdown = classNames({
            'Countdown': true,
            'cover': true,
            'col': true,
            'center': true,
            'relative': true,
        })
        var countdowncontainer = classNames({
            'CountdownContainer': true,
            'col': true,
            'center': true,
            'relative': true,
        })
        var countdowntext = classNames({
            'CountdownText': true,
            'countdowntext': true,
            't2': true,
            'textcenter': true,
        })
        var countdowndigit = classNames({
            'CountdownDigit': true,
            'countdowntext': true,
            't1': true,
            'textcenter': true,
        })
        var countdowntime = classNames({
            'CountdownTime': true,
            'cover': true,
            'countdowntext': true,
            't3': true,
            'textcenter': true,
            'col': true,
            'center': true,
            'relative': true,
        })
        var countdownunit = classNames({
            'CountdownUnit': true,
            'row': true,
            'start': true,
            'relative': true,
        })
        return(
            <div className = {countdown}>
                <div className = {countdowncontainer}>
                    <div className = {countdowntext}>
                        CHECK BACK IN
                    </div>
                    <div className = {countdowntime}>
                        <div className = {countdownunit}>
                            <div className = {countdowndigit}>
                                {this.state.days}
                            </div> DAYS 
                        </div>
                        <div className = {countdownunit}>
                            <div className = {countdowndigit}>
                                {this.state.hours}
                            </div> HOURS 
                        </div>
                        <div className = {countdownunit}>
                            <div className = {countdowndigit}>
                                {this.state.minutes}
                            </div> MINUTES 
                        </div>
                        <div className = {countdownunit}>
                            <div className = {countdowndigit}>
                                {this.state.seconds}
                            </div> SECONDS 
                        </div>
                    </div>
                    <div className = {countdowntext}>
                        - F1LTHY
                    </div>
                </div>
            </div>
        )
    }
}
class Fader extends React.Component {
    constructor(props) {
        super(props);
        this.handleFade = this.handleFade.bind(this);
        this.state = {
            visible: true
        };
    }
    handleFade() {
        this.setState({
            visible: true
        }, function() {
            this.setState({
                visible: false
            })
        })
    }
    componentDidUpdate(prevProps) {
        if ((this.props.loading !== prevProps.loading) && this.props.loading === false) {
            this.handleFade();
        }
    }
    render() {
        var fader = classNames({
            'Fader': true,
            'fg': true,
            'slowest': this.state.visible === false,
            'invisible': this.state.visible === false,
            'col': true,
            'center': true
        })
        var faderloading = classNames({
            'FaderLoading': true,
            'cover': true,
            'col': true,
            'center': true,
            'relative': true,
        })
        var faderloadingtext = classNames({
            'FaderLoadingText': true,
            't2': true
        })
        var faderloadingimage = classNames({
            'FaderLoadingImage': true,
        })
        var loading = this.props.loading ? 
            <div className = {faderloading}>
                <div className = {faderloadingimage}>

                </div>
                <div className = {faderloadingtext}>
                    LOADING F1LTHYWARE...
                </div>
            </div>
        : null
        return (
            <div className = {fader}>
                {loading}
            </div>
        )
    }
}
class Background extends React.Component {
    render() {
        var background = classNames({
            'Background': true,
            'BackgroundZoom': this.props.page !== 'HOME',
            'bg': true,
            'col': true,
            'center': true,
            'quick': true,
        })
        var vignette = classNames({
            'Vignette': true,
            'col': true,
            'center': true,
        })
        return (
            <div className = {background}>
                <div className = {vignette}>
                </div>
            </div>
        )
    }
}
class Instructions extends React.Component{
    constructor(props) {
        super(props);
        this.handleFade = this.handleFade.bind(this);
        this.state = {
            visible: true
        };
    }
    handleFade() {
        setTimeout(() => {
            this.setState({visible: false});
        }, 10000)
    }
    componentDidUpdate(prevProps) {
        if ((this.props.loading !== prevProps.loading) && this.props.loading === false) {
            this.handleFade();
        }
    }
    render() {
        var instructions = classNames({
            'Instructions': true,
            'cover': true,
            'col': true,
            'start': true,
            'slow': true
        })
        var instruction = classNames({
            'Instruction': true,
            'row': true,
            'start': true
        })
        var instructiondiagram = classNames({
            'InstructionDiagram': true
        })
        var instructiontext = classNames({
            'InstructionText': true,
            't4': true
        })
        return (
            this.state.visible === true ?
            <div className = {instructions}>
                <div className = {instruction}>
                    <div className = {instructiondiagram} style = {{backgroundImage: 'url(' + process.env.PUBLIC_URL + '/images/arrowkeys.png)'}}></div>
                    <div className = {instructiontext}>
                        Use the arrow keys or click the arrows on the tv screen to navigate through the menu.
                    </div>
                </div>
                <div className = {instruction}>
                    <div className = {instructiondiagram} style = {{backgroundImage: 'url(' + process.env.PUBLIC_URL + '/images/click.png)'}}></div>
                    <div className = {instructiontext}>
                        Press enter or click on the tv screen to go to the selected page.
                    </div>
                </div>
                <div className = {instruction}>
                    <div className = {instructiondiagram} style = {{backgroundImage: 'url(' + process.env.PUBLIC_URL + '/images/escape.png)'}}></div>
                    <div className = {instructiontext}>
                        Press escape or click on the X to return to the home screen.
                    </div>
                </div>
                <div className = {instruction} id = "About">
                    <div className = {instructiontext}>
                        Site by @mollysoulja on twitter
                    </div>
                </div>
            </div>
            : null
        )
    }
}
class Screen extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.reactToKeys = this.reactToKeys.bind(this);
        this.reactToIntroKeys = this.reactToIntroKeys.bind(this);
        this.handleArrowClick = this.handleArrowClick.bind(this);
        this.handleNavPageClick = this.handleNavPageClick.bind(this);
        this.updateCSS = this.updateCSS.bind(this);
        this.fadeOut = this.fadeOut.bind(this);
        this.crtRef = React.createRef();
        this.introRef = React.createRef();
        this.state = {
            current: 1,
            select: false,
        };
    }
    updateCSS() {
        document.documentElement.style.setProperty('--currentnavigation', 'url(' + process.env.PUBLIC_URL + '/images/nav' + this.state.current + '.png)');
        document.documentElement.style.setProperty('--currentbg', this.props.page !== 'HOME' ? 'url(' + process.env.PUBLIC_URL + '/images/bg' + this.state.current + '.png)' : 'url(' + process.env.PUBLIC_URL + '/images/homebg.png)');
        document.documentElement.style.setProperty('--currentcrt', this.props.page !== 'HOME' ? 'url(' + process.env.PUBLIC_URL + '/images/crt' + this.state.current + '.png)' : 'url(' + process.env.PUBLIC_URL + '/images/homecrt.png)');
    }
    handleClick(e) {
        if (this.state.select === true) {
            this.props.handlePageChange(pages[this.state.current]);
            document.documentElement.style.setProperty('--flicker', 'none');
        }
        else {
            this.props.handlePageChange('SELECT');
            this.setState({select: true});
            document.documentElement.style.setProperty('--flicker', this.props.windowratio < 1 ? 'none' : flicker);
        }
    }
    handleEnter(e) {
        if (this.state.select === false && this.props.page === 'HOME'){
            this.fadeOut();
            this.setState({select: true});
            setTimeout(() => {
                this.crtRef.current.focus();
                this.props.handlePageChange('SELECT');
                document.documentElement.style.setProperty('--flicker', this.props.windowratio < 1 ? 'none' : flicker);
            }, 2000)
        }
    }
    handleCancel(e) {
        if (this.state.select === true) {
            if (e) {
                e.stopPropagation();
            }
            if (this.props.page !== 'SELECT' && this.props.page !== 'HOME') {
                this.props.handlePageChange('SELECT');
                this.crtRef.current.focus();
                document.documentElement.style.setProperty('--flicker', this.props.windowratio < 1 ? 'none' : flicker);
            }
            else if (this.props.page === 'SELECT') {
                this.props.handlePageChange('HOME');
                this.setState({select: false});
            }
        }
    }
    handleNavPageClick(e, id) {
        this.setState({current: id}, function() {
            this.updateCSS();
        })
    }
    handleArrowClick(e, direction) {
        if (this.state.select === true) {
            e.stopPropagation();
            if (direction === 'L' && this.state.current > 0) {
                this.setState({current: this.state.current - 1}, function() {
                    this.updateCSS();
                });
            }
            else if (direction === 'R' && this.state.current < (pages.length - 1)){
                this.setState({current: this.state.current + 1}, function() {
                    this.updateCSS();
                });
            }
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.page !== prevProps.page) {
            this.updateCSS();
        }
        if (this.props.muted !== prevProps.muted) {
            this.setState({muted: this.props.muted})
        }
    }
    componentDidMount () {
        this.introRef.current.focus();
        this.updateCSS();
        flicker = getComputedStyle(document.documentElement).getPropertyValue('--flicker');
    }
    reactToKeys(key) {
        if ((key.keyCode === 37 || key.keyCode === 39) && (this.state.current >= 0 && this.state.current <= (pages.length - 1)) && (this.props.page === 'SELECT')) {
            if (this.state.select === true) {
                if (key.keyCode === 37 && this.state.current > 0) {
                    this.setState({current: this.state.current - 1}, function() {
                        this.updateCSS();
                    });
                }
                else if (key.keyCode === 39 && this.state.current < (pages.length - 1)){
                    this.setState({current: this.state.current + 1}, function() {
                        this.updateCSS();
                    });
                }
            }
        }
        else if (key.keyCode === 13) {
            this.handleClick();
        }
        else if (key.keyCode === 27) {
            this.handleCancel();
        }
    }
    reactToIntroKeys(key) {
        if (key.keyCode === 13 || key.keyCode === 32) {
            this.handleClick();
        }
    }
    fadeOut() {
        var video = document.getElementById('IntroVideo');
        if (video) {
            if (video.volume > 0.1) {
                video.volume -= 0.1;
                setTimeout(this.fadeOut, 100);
            }
            else {
                video.muted = true;
            }
        }
    }
    render() {
        var crtcontainer = classNames({
            'CRTContainer': true,
            'CRTContainerZoom': this.props.page !== 'HOME',
            'mg1': true,
            'col': true,
            'center': true,
            'quick': true,
        })
        var crt = classNames({
            'CRT': true,
            'CRTZoom': this.props.page !== 'HOME' && this.props.page !== 'SELECT',
            'col': true,
            'center': true
        })
        var screen = classNames({
            'Screen': true
        })
        var screenblur = classNames({
            'ScreenBlur': true,
            'ScreenBlurZoom': this.props.page !== 'HOME' && this.props.page !== 'SELECT',
            'cover': true,
            'absolute': true,
            'col': true,
            'center': true
        })
        var pixels = classNames({
            'Pixels': true,
            'invisible': (this.props.page !== 'HOME' && this.props.page !== 'SELECT') || (this.props.page === 'SELECT' && this.props.windowratio < 1),
        })
        var image = classNames({
            'Image': true,
            'ImageZoom': this.props.page !== 'HOME' && this.props.page !== 'SELECT',
            'ImageZoomLandscape': this.props.page !== 'HOME' && this.props.page !== 'SELECT' && this.props.windowratio > 1,
            'cover': true,
            'absolute': true,
            'col': true,
            'center': true,
        })
        var navigation = classNames({
            'Navigation': true,
            'col': true,
            'end': true,
            'invisible': this.props.page !== 'HOME' && this.props.page !== 'SELECT',
            'click': this.props.page === 'HOME',
            'quick': true
        })
        var navcontainer = classNames({
            'NavContainer': true,
            'row': true,
            'center': true,
            'relative': true,
        })
        var navimage = classNames({
            'NavImage': true,
            'click': true
        })
        var navpages = classNames({
            'NavPages': true,
            'cover': true,
            'row': true,
            'center': true,
            'relative': true,
        })
        var direction = classNames({
            'Direction': true,
            'click': this.props.page === 'HOME' || this.props.page === 'SELECT',
        })
        var cancel = classNames({
            'Cancel': true,
            'click': true,
            'invisible': this.props.page === 'HOME' || this.props.page === 'SELECT',
            'quick': true,
            'scale': true
        })
        var intro = classNames({
            'Intro': true,
            'cover': true,
            'absolute': true,
            'mg3': true,
            'click': true,
            'slowest': true,
        })
        var introvideo = classNames({
            'IntroVideo': true,
            'IntroEnd': this.state.select === true,
            'cover': true,
            'absolute': true,
            'slowest': true,
        })
        var introskip = classNames({
            'IntroSkip': true,
            'absolute': true,
            't3': true,
            'click': true,
            'quick': true,
            'scale': true,
        })
        var introtext = classNames({
            'IntroText': true,
            'absolute': true,
            't2': true,
            'textcenter': true,
        })
        var rightarrow = {
            transform: 'scaleX(-1)',
            opacity: this.state.current === 2 ? 0 : 1
        }
        var leftarrow = {
            opacity: this.state.current === 0 ? 0 : 1
        }
        var IntroText = this.props.page === 'HOME' && isMobile ? 
            <div className = {introtext}>
                Click to Play!
            </div>
        : null
        var Navigation = this.props.page === 'SELECT' ?
        <div className = {navigation}>
            <div className = {navimage} onClick = {this.handleClick}></div>
            <div className = {navcontainer}>
                <div className = {direction} onClick={(e) => {this.handleArrowClick(e, 'L');}} style = {leftarrow}></div>
                <div className = {navpages}>
                    {pages.map((i, index) => (
                        <NavPage    key = {index}
                                    id = {index}
                                    image = {'nav' + index}
                                    current = {this.state.current}
                                    handleClick = {this.handleNavPageClick}>
                        </NavPage>
                    ))}
                </div>
                <div className = {direction} onClick={(e) => {this.handleArrowClick(e, 'R');}} style = {rightarrow}></div>
            </div>
        </div> : this.props.page === 'HOME' ?
        <div className = {navigation}>
            <div className = {intro}>
                <video id = {'IntroVideo'} className = {introvideo} muted = {this.props.muted} autoPlay playsInline onEnded = {(e) => this.handleEnter()}>
                    <source src = {video0} type="video/mp4" />
                </video>
                <div className = {introskip} ref = {this.introRef} tabIndex="1" onKeyDown = {this.reactToKeys} onClick = {(e) => this.handleEnter()}>
                    Skip Intro...
                </div>
                {IntroText}
            </div>
        </div> : 
        <div className = {navigation}>
            {/* <div className = {navimage} onClick={(e) => {this.handleEnter();}}></div> */}
        </div>
        return (
            <div className = {crtcontainer}>
                <div ref = {this.crtRef} tabIndex="1" className = {crt} onKeyDown = {this.reactToKeys}>
                    <div className = {screen}>
                        <div className = {screenblur}></div>
                        <div className = {pixels}></div>
                        <div className = {image}>
                            <div className = {cancel} onClick = {this.handleCancel}>
                            </div>
                            {Navigation}
                            <Music  vfx = {this.props.vfx}
                                    page = {this.props.page}
                                    handlePageChange = {this.props.handlePageChange}/>
                            <Socials    vfx = {this.props.vfx}
                                        page = {this.props.page}
                                        handlePageChange = {this.props.handlePageChange}/>
                            <Shop      vfx = {this.props.vfx}
                                        page = {this.props.page}
                                        handlePageChange = {this.props.handlePageChange}/>
                            <Thanks     vfx = {this.props.vfx}
                                        page = {this.props.page}
                                        handlePageChange = {this.props.handlePageChange}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
class NavPage extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick(e, id) {
        this.props.handleClick(e, id);
    }
    render() {
        var navpage = classNames({
            'NavPage': true,
            'navoutline': this.props.current === this.props.id,
            'click': true,
            'quick': true
        })
        return (
            <div key = {this.props.id} id = {this.props.id} className = {navpage} style = {{backgroundImage: "url(" + process.env.PUBLIC_URL + "/images/" + this.props.image + ".png)"}} onClick = {(e) => {this.props.handleClick(e, this.props.id)}}>
            </div>
        )
    }
}
class Music extends React.Component {
    constructor(props) {
        super(props);
        this.handleSongClick = this.handleSongClick.bind(this);
        this.handleLinkClick = this.handleLinkClick.bind(this);
        this.state = {
            songs: [],
            clicked: 1
        }
    }
    handleSongClick(id) {
        this.setState({ clicked: id });
    }
    handleLinkClick(link) {
        window.open(link, '_blank');
    }
    componentDidMount() {
        axios.get(url, {
            withCredentials: true,
            params: {
                GETSONGS: ''
            },
            credentials: 'same-origin'
        })
        .then(response => response.data)
        .then((data) => {
            this.setState({ songs: data });
        })
    }
    render() {
        var music = classNames({
            'Music': true,
            'musiccursor': true,
            'fullscreen': true,
            'container': true,
            'col': true,
            'center': true,
        })
        var musiccontainer = classNames({
            'MusicContainer': true,
            'col': true,
            'center': true,
        })
        var musicselect = classNames({
            'MusicSelect': true,
            'cover': true,
            'row': true,
            'relative': true,
            'center': true,
        })
        var musicdisplay = classNames({
            'MusicDisplay': true,
            'cover': true,
            'row': true,
            'around': true,
            'relative': true
        })
        var songdisplay = classNames({
            'SongDisplay': true,
            'cover': true,
            'relative': true
        })
        var songlinks = classNames({
            'SongLinks': true,
            'cover': true,
            'relative': true,
            'col': true,
            'start': true,
            'quick': true
        })
        if (this.props.page !== 'MUSIC') {
            return null;
        }
        else {
            return (
                <div className = {music}>
                    <div className = {musiccontainer}>
                        <div className = {musicselect}>
                            {this.state.songs.map((s) => (
                                <Song       handleSongClick = {this.handleSongClick} 
                                            key = {s.id} 
                                            id = {s.id} 
                                            name = {s.name} 
                                            image = {s.image} 
                                            links = {s.links}
                                            clicked = {this.state.clicked}/>
                            ))}
                        </div>
                        <div className = {musicdisplay}>
                            <div className = {songdisplay} style = {{backgroundImage: this.state.songs.length !== 0 ? "url(" + process.env.PUBLIC_URL + "/images/" + this.state.songs[this.state.clicked - 1].image + ".png)" : "url(" + process.env.PUBLIC_URL + "/placeholder.png"}}>
                            </div>
                            <div className = {songlinks}>
                                {this.state.songs.length !== 0 ? this.state.songs[this.state.clicked - 1].links.map((l) => (
                                    <SongLink       key = {l.id}
                                                site = {l.site}
                                                link = {l.link}
                                                handleLinkClick = {this.handleLinkClick}/>
                                )) : null}
                            </div>
                        </div>
                    </div>
                </div>
            ) 
        }
    }
}
class Song extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick() {
        this.props.handleSongClick(this.props.id);
    }
    render() {
        var song = classNames({
            'Song': true,
            'musicoutline': true,
            'col': true,
            'center': true,
            'relative': true,
            'quick': true,
            'scale': true,
        })
        var songimage = classNames({
            'SongImage': true,
            'cover': true,
        })
        var songname = classNames({
            'SongName': true,
            'musicmaintext': true,
            't4': true,
            'col': true,
            'center': true,
            'textcenter': true
        })
        return (
            <div className = {song} onClick = {this.handleClick}>
                <div className = {songimage} style = {{backgroundImage: "url(" + process.env.PUBLIC_URL + "/images/" + this.props.image + ".png)"}}>
                </div>
                <div className = {songname}>
                    {this.props.name}
                </div>
            </div>
        )
    }
}
class SongLink extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick() {
        if (this.props.link !== '') {
            this.props.handleLinkClick(this.props.link);
        }
    }
    render() {
        var songlink = classNames({
            'SongLink': true,
            't3': true,
            'quick': true,
            'scale': true,
            'invalidtext': this.props.link === ''
        })
        return (
            <div className = {songlink} onClick = {this.handleClick}>
                {this.props.site}
            </div>
        )
    }
}
class Socials extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.handleSocialClick = this.handleSocialClick.bind(this);
        this.handleSocialHover = this.handleSocialHover.bind(this);
        this.state = {
            clicked: 0,
            hovered: 1,
            socials: []
        }
    }
    handleClick() {
        
    }
    handleSocialClick(link) {
        window.open(link, '_blank');
    }
    handleSocialHover(id) {
        this.setState({hovered: id});
    }
    componentDidMount() {
        axios.get(url, {
            withCredentials: true,
            params: {
                GETSOCIALS: ''
            },
            credentials: 'same-origin'
        })
        .then(response => response.data)
        .then((data) => {
            this.setState({ socials: data });
        })
    }
    render() {
        var socials = classNames({
            'Socials': true,
            'fullscreen': true,
            'col': true,
            'center': true,
        })
        var socialscontainer = classNames({
            'SocialsContainer': true,
            'socialsmenu': true,
            'col': true,
            'start': true,
        })
        var socialstitle = classNames({
            'SocialsTitle': true,
            'socialsmaintext': true,
            't2': true,
            'socialsmenu': true,
        })
        if (this.props.page !== 'SOCIALS') {
            return null;
        }
        else {
            return (
                <div className = {socials}>
                    <div className = {socialscontainer} id = 'SocialsContainer'>
                        <div className = {socialstitle}>
                            Socials
                        </div>
                        {this.state.socials.map((s) => (
                                <Social     handleSocialClick = {this.handleSocialClick}
                                            handleSocialHover = {this.handleSocialHover} 
                                            key = {s.id} 
                                            id = {s.id} 
                                            site = {s.site} 
                                            link = {s.link} 
                                            image = {s.image} 
                                            level = {s.level}
                                            hp = {s.hp}
                                            hovered = {this.state.hovered}
                                            clicked = {this.state.clicked}/>
                            ))}
                    </div>
                </div>
            )
        }
    }
}
class Social extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.handleHover = this.handleHover.bind(this);
    }
    handleClick() {
        this.props.handleSocialClick(this.props.link);
    }
    handleHover() {
        this.props.handleSocialHover(this.props.id);
    }
    render() {
        var social = classNames({
            'Social': true,
            'row': true,
            'start': true,
            'relative': true,
            'click': true,
            'quick': true
        })
        var socialimage = classNames({
            'SocialImage': true,
        })
        var socialinfo = classNames({
            'SocialInfo': true,
            'col': true,
            'start': true,
            'relative': true
        })
        var socialtitle = classNames({
            'SocialTitle': true,
            'socialsmaintext': true,
            't3': true,
            'textcenter': true
        })
        var socialcategory = classNames({
            'SocialCategory': true,
            'row': true,
            'center': true,
            'relative': true,
        })
        var socialdata = classNames({
            'SocialData': true,
            'socialsmaintext': true,
            't3': true,
            'textcenter': true
        })
        var socialcategorytitle = classNames ({
            'SocialCategoryTitle': true,
            'socialsbluetext': true,
            't3': true,
            'textcenter': true
        })
        var socialpointer = classNames({
            'SocialPointer': true,
            'invisible': this.props.hovered !== this.props.id
        })
        return (
            <div className = {social} onMouseEnter = {this.handleHover}  onClick = {this.handleClick}>
                <div className = {socialpointer}>
                </div>
                <div className = {socialimage} tabIndex = {this.props.id} style = {{backgroundImage: "url(" + process.env.PUBLIC_URL + "/images/" + this.props.image + ".png)"}}>
                </div>
                <div className = {socialinfo}>
                    <div className = {socialtitle}>
                        {this.props.site}
                    </div>
                    <div className = {socialcategory}>
                        <div className = {socialcategorytitle}>
                            LV
                        </div>
                        <div className = {socialdata}>
                            {this.props.level}
                        </div>
                    </div>
                    <div className = {socialcategory}>
                        <div className = {socialcategorytitle}>
                            HP
                        </div>
                        <div className = {socialdata}>
                            {this.props.hp}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
class Shop extends React.Component {
    constructor(props) {
        super(props);
        this.handleCartClick = this.handleCartClick.bind(this);
        this.handleProductClick = this.handleProductClick.bind(this);
        this.state = {
            products: [],
            clicked: 1
        }
    }
    componentDidMount() {
        axios.get(url, {
            withCredentials: true,
            params: {
                GETALL: ''
            },
            credentials: 'same-origin'
        })
        .then(response => response.data)
        .then((data) => {
            this.setState({ products: data })
         })
    }
    handleCartClick() {
        if (this.props.page === 'SHOP') {
            this.props.handlePageChange('CART');
        }
        else {
            this.props.handlePageChange('SHOP');
        }
    }
    handleProductClick(id) {
        this.setState({clicked: id});
    }
    render() {
        var shopcontainer = classNames({
            'ShopContainer': true,
            'row': true,
            'start': true,
        })
        var shopselect = classNames({
            'ShopSelect': true,
            'col': true,
            'start': true,
            'relative': true
        })
        var shopdisplay = classNames({
            'ShopDisplay': true,
            'col': true,
            'center': true,
            'relative': true
        })
        var productname = classNames({
            'ProductName': true,
            'col': true,
            'start': true,
            'relative': true,
            'shopmaintext': true,
            't3': true
        })
        var productdescription = classNames({
            'ProductDescription': true,
            'shopmaintext': true,
            't4': true
        })
        var carttoggle = classNames({
            'CartToggle': true,
            'shopmaintext': true,
            't3': true,
            'click': true,
            'quick': true,
            'scale': true,
        })
        var shopbar = classNames({
            'ShopBar': true,
            'row': true,
            'relative': true,
            'start': true,
        })
        var merch = classNames({
            'Merch': true,
            'cover': true,
            'row': true,
            'relative': true,
            'start': true,
        })
        var shop = classNames({
            'Shop': true,
            'fullscreen': true,
            'container': true,
            'col': true,
            'center': true,
        })
        var carttext = this.props.page === 'SHOP' ? 'CHECKOUT': 'BACK';
        const name = this.state.products.length !== 0 ? this.state.products[this.state.clicked - 1].name : "";
        const description = this.state.products.length !== 0 ? this.state.products[this.state.clicked - 1].description : "";
        const locked = this.state.products.length !== 0 ? this.state.products[this.state.clicked - 1].locked : "locked";
        const images = this.state.products.length !== 0 ? this.state.products[this.state.clicked - 1].images : null;
        var merchcomponent = this.props.page === 'SHOP' ? 
        <div className = {merch}>
        {this.state.products.map((p, index) => (
            <Product    handleProductClick = {this.handleProductClick} 
                        key = {p.id} 
                        id = {p.id} 
                        sort = {p.sort}
                        images = {p.images} 
                        price = {p.price}
                        locked = {p.locked}
                        clicked = {this.state.clicked}
                        page = {this.props.page}/>
        ))}
        </div>: null;
        var productnamecomponent = this.props.page === 'SHOP' ? 
        <div className = {productname}>
            {name}
            <div className = {productdescription}>
                {description}
            </div>
        </div> : null;
        var shopdisplaycomponent = this.props.page === 'SHOP' ? 
        <div className = {shopdisplay}>
            <ShopDisplay    style = {{backgroundImage: this.state.products.length !== 0 ? "url(" + process.env.PUBLIC_URL + "/images/" + this.state.products[this.state.clicked - 1].images[0] : "url(" + process.env.PUBLIC_URL + "/images/placeholder.png"}}
                            clicked = {this.state.clicked}
                            page = {this.props.page}
                            locked = {locked}
                            images = {images}>
            </ShopDisplay>
            <Form   locked = {locked}
                    id = {this.state.products[this.state.clicked - 1].id}
                    sort = {this.state.clicked}
                    page = {this.props.page}/>
        </div> : null;
        if (this.props.page !== 'SHOP' && this.props.page !== 'CART') {
            return null;
        }
        else {
            return (
                <div className = {shop}>
                    <div className = {shopcontainer}>
                        <div className = {shopselect}>
                            <div className = {shopbar}>
                                <div className = {carttoggle} onClick = {this.handleCartClick}>
                                    {carttext}
                                </div>
                            </div>
                            <Cart   vfx = {this.props.vfx}
                                    page = {this.props.page}
                                    handlePageChange = {this.props.handlePageChange}/>
                            {merchcomponent}
                            {productnamecomponent}
                        </div>
                        {shopdisplaycomponent}
                    </div>
                </div>
            ) 
        }
    }
}
class ShopDisplay extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.state = {
            clicked: 0,
        }
    }
    handleClick(id) {
        this.setState({clicked: id});
    }
    componentDidUpdate(prevProps) {
        if (this.props.clicked !== prevProps.clicked && this.props.page === 'SHOP') {
            this.setState({clicked: 0});
        }
    }
    render() {
        var productdisplay = classNames({
            'ProductDisplay': true,
            'row': true,
            'center': true,
            'relative': true
        })
        var productdisplaypreviews = classNames({
            'ProductDisplayPreviews': true,
            'col': true,
            'start': true,
            'relative': true
        })
        var productdisplayimage = classNames({
            'ProductDisplayImage': true,
            'ProductDisplayImageLocked': this.props.locked
        })
        var previews = this.props.images.length > 0 ? 
        <div className = {productdisplaypreviews}>
            {this.props.images.filter(image => image.includes('preview')).map((i, index) => (
                <ShopDisplayPreview key = {index}
                                    id = {index}
                                    image = {i}
                                    locked = {this.props.locked}
                                    clicked = {this.state.clicked}
                                    handleClick = {this.handleClick}>
                </ShopDisplayPreview>
            ))}
        </div>
        :null
        return (
            <div className = {productdisplay}>
                {previews}
                <div className = {productdisplayimage} style = {{backgroundImage: this.props.images.length !== 0 ? this.state.clicked === 0 ? "url(" + process.env.PUBLIC_URL + "/images/" + this.props.images[0] : "url(" + process.env.PUBLIC_URL + "/images/" + this.props.images.filter(image => image.includes('preview'))[this.state.clicked] : "url(" + process.env.PUBLIC_URL + "/images/placeholder.png"}}>
                </div>
            </div>
        )
    }
}
class ShopDisplayPreview extends React.Component {
    render() {
        var productdisplaypreviewimage = classNames({
            'ProductDisplayPreviewImage': true,
            'ProductDisplayPreviewImageLocked': this.props.locked,
            'shopoutline': this.props.clicked === this.props.id,
            'click': true,
            'quick': true,
            'scale': true
        })
        return (
            <div key = {this.props.id} id = {this.props.id} className = {productdisplaypreviewimage} style = {{backgroundImage: "url(" + process.env.PUBLIC_URL + "/images/" + this.props.image}} onClick = {() => this.props.handleClick(this.props.id, this.props.image)}>
            </div>
        )
    }
}
class Product extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick() {
        this.props.handleProductClick(this.props.sort);
    }
    render() {
        var productimage = classNames({
            'ProductImage': true,
            'cover': true,
            'ProductLocked': this.props.locked !== null,
        })
        var producttext = classNames({
            'ProductText': true,
            'col': true,
            'relative': true,
            'center': true,
        })
        var product = classNames({
            'Product': true,
            'shopoutline': this.props.clicked === this.props.sort,
            'col': true,
            'between': true,
            'relative': true,
            'click': true,
            'quick': true,
            'scale': true
        })
        var productprice = classNames({
            'ProductPrice': true,
            'shopredtext': true,
            't4': true,
        })
        if ((this.props.page !== 'SHOP')) {
            return null;
        }
        else {
            return (
                <div className = {product}  onClick = {this.handleClick}>
                    <div className = {productimage} style = {{backgroundImage: "url(" + process.env.PUBLIC_URL + "/images/" + this.props.images[1]}}>
                    </div>
                    <div className = {producttext}>
                        <div className = {productprice}>
                            ${this.props.price}
                        </div>
                    </div>
                </div>
                
            )
        }
    }
}
class Cart extends React.Component {
    constructor(props) {
        super(props);
        this.handleSuccess = this.handleSuccess.bind(this);
        this.handleBackClick = this.handleBackClick.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        this.handleDiscountChange = this.handleDiscountChange.bind(this);
        this.getCart = this.getCart.bind(this);
        this.getDomestic = this.getDomestic.bind(this);
        this.state = {
            items: [],
            total: 0,
            domestic: true,
            discount: ''
        }
    }
    handleBackClick() {
        this.props.handlePageChange('SHOP');
    }
    handleSuccess() {
        this.props.handlePageChange('THANKS');
    }
    handleRemove(id) {
        this.getCart()
    }
    handleDiscountChange(event) {
        this.setState({discount: event.target.value}, function() {
            this.getDiscount();
        });
    }
    getCart() {
        axios.get(url, {
            withCredentials: true,
            params: {
                GETCART: ''
            },
            credentials: 'same-origin'
        })
        .then(response => response.data)
        .then((data) => {
            this.setState({ 
                items: data[0], 
                total: data[1]
            })
        })
    }
    getDomestic() {
        const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
        this.setState({domestic: timeZone.includes('America')});
    }
    getDiscount() {
        axios.get(url, {
            withCredentials: true,
            params: {
                GETDISCOUNT: '',
                CODE: this.state.discount
            },
            credentials: 'same-origin'
        })
        .then(response => response.data)
        .then((data) => {
            if (data.valid === true) {
                discount = data
                this.forceUpdate();
            }
            else {
                discount = null
            }
        })
    }
    componentDidMount() {
        this.getCart();
        this.getDomestic();
    }
    componentDidUpdate(prevProps) {
        if (this.props.page !== prevProps.page && this.props.page === 'CART') {
            this.getCart()
        }
    }
    render() {
        var subtotalcost = this.state.total.toFixed(2);
        var shippingindex = this.state.domestic ? 0 : 1;
        var shippingcost = shippingdata ? shippingdata[shippingindex].type === 'Percentage' ? ((parseFloat(shippingdata[shippingindex].value)/100).toFixed(2) * this.state.total).toFixed(2) : parseFloat(shippingdata[shippingindex].value).toFixed(2) : 0.00;
        var discountcost = discount ? discount.valid === true ? discount.type === 'Percentage' ? ((parseFloat(discount.value)/100).toFixed(2) * this.state.total).toFixed(2) : parseFloat(discount.value).toFixed(2) : 0 : 0;
        var totalcost = (parseFloat(subtotalcost) + parseFloat(shippingcost) - parseFloat(discountcost)).toFixed(2);
        var cart = classNames({
            'Cart': true,
            'cover': true,
            'col': true,
            'relative': true,
            'start': true,
        })
        var itemscontainer = classNames({
            'ItemsContainer': true,
            'col': true,
            'relative': true,
            'start': true,
        })
        var totalcontainer = classNames({
            'TotalContainer': true,
            'col': true,
            'relative': true,
            'textright': true
        })
        var subtotal = classNames({
            'Subtotal': true,
            't2': true,
            'shopmaintext': true
        })
        var total = classNames({
            'Total': true,
            't1': true,
            'shopmaintext': true
        })
        var shipping = classNames({
            'Shipping': true,
            't2': true,
            'shopmaintext': true
        })
        var discounttotal = classNames({
            'DiscountTotal': true,
            't2': true,
            'shopmaintext': true
        })
        var discountcode = classNames({
            'DiscountCode': true,
            'row': true,
            'end': true,
            'relative': true
        })
        var discounttext = classNames({
            'DiscountText': true,
            'shopmaintext': true,
            't3': true
        })
        var discountinput = classNames({
            'DiscountInput': true,
            'shopmaintext': true,
            't3': true,
            'relative': true,
            'click': true,
            'quick': true,
        })
        var cartitems = (this.state.items ? this.state.items.map((i) => (
            <CSSTransition
                key = {i.id}
                classNames = "fade"
                timeout = {{ enter: 200, exit: 200 }}>
                <Item   key = {i.id} 
                        id = {i.id} 
                        name = {i.name} 
                        quantity = {i.quantity} 
                        size = {i.size}
                        image = {i.image}
                        price = {i.price}
                        handleRemove = {this.handleRemove}/>
            </CSSTransition>
        )):null)
        var discountdisplay = discount !== null ?
            <div className = {discounttotal}>
                DISCOUNT: -${discountcost}
            </div>
        : null
        if (this.props.page !== 'CART') {
            return null;
        }
        else {
            return (
                <div className = {cart}>
                    <div className = {itemscontainer}>
                        <TransitionGroup component={null}>
                            {cartitems}
                        </TransitionGroup>
                    </div>
                    <div className = {totalcontainer}>
                        <div className = {discountcode}>
                            <div className = {discounttext}>
                                CODE: 
                            </div>
                            <input className = {discountinput} value = {this.state.discount} onChange = {this.handleDiscountChange}></input>
                        </div>
                        <div className = {subtotal}>
                            SUBTOTAL: ${subtotalcost}
                        </div>
                        <div className = {shipping}>
                            EST. SHIPPING: ${shippingcost}
                        </div>
                        {discountdisplay}
                        <div className = {total}>
                            TOTAL: ${totalcost}
                        </div>
                        <Checkout   items = {this.state.items}
                                    page = {this.props.page}
                                    handleSuccess = {this.handleSuccess}/>
                    </div>
                </div>
            )
        }
        
    }
}
class Item extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick() {
        axios.get(url, {
            withCredentials: true,
            params: {
                DROP: '',
                ID: this.props.id
            },
            credentials: 'same-origin'
        })
        .then(response => response.data)
        .then((data) => {
            this.props.handleRemove();
         })
    }
    render() {
        var size = ('')
        if (this.props.size !== '0') {
            size = (this.props.size)
        }
        var item = classNames({
            'Item': true,
            'row': true,
            'relative': true,
            'start': true,
        })
        var remove = classNames({
            'Remove': true,
            'click': true,
            'quick': true,
            'scale': true
        })
        var itemimage = classNames({
            'ItemImage': true,
        })
        var itemtext = classNames({
            'ItemText': true,
            'col': true,
            'start': true,
            'relative': true,
            't2': true,
            'shopmaintext': true,
        })
        var itemdata = classNames({
            'ItemData': true,
            't4': true,
            'shopmaintext': true,
        })
        var itemprice = classNames({
            'ItemPrice': true,
            't2': true,
            'shopmaintext': true
        })
        return (
            <div className = {item}>
                <div className = {itemimage} style = {{backgroundImage: "url(" + process.env.PUBLIC_URL + "/images/" + this.props.image}}>
                </div>
                <div className = {itemtext}>
                    {this.props.name}
                    <div className = {itemdata}>
                    Size: {size} Qty: {this.props.quantity}
                    </div>
                </div>
                <div className = {remove} onClick = {this.handleClick}>
                </div>
                <div className = {itemprice}>
                    ${Number(this.props.price).toFixed(2)}
                </div>
            </div>
        )
    }
}
class Form extends React.Component {
    constructor(props) {
        super(props);
        this.handleAdd = this.handleAdd.bind(this);
        this.getInfo = this.getInfo.bind(this);
        this.handleValueChange = this.handleValueChange.bind(this);
        this.add = this.add.bind(this);
        this.products = [];
        this.state = {
            quantity: 1,
            size: '',
            sizes: [],
            quantities: [],
            stock: 0
        }
    }
    componentDidMount() {
        if (this.props.page === 'SHOP' && this.props.sort !== 0) {
            this.getInfo()
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.sort !== prevProps.sort && this.props.page === 'SHOP' && this.props.sort !== 0) {
            this.getInfo()
        }
    }
    getInfo() {
        axios.get(url, {
            withCredentials: true,
            params: {
                GETALL: ''
            },
            credentials: 'same-origin'
        })
        .then(response => response.data)
        .then((data) => {
            this.products = data;
            var size = this.products[this.props.sort - 1].options[0];
            this.setState({
                size: size,
                sizes: this.products[this.props.sort - 1].options,
                quantities: Array.from({length: data[this.props.sort - 1].max}, (_, i) => i + 1),
                stock: this.products[this.props.sort - 1].stock.filter(s => s.size === (size))[0].stock
            })
         })
    }
    handleAdd() {
        if (this.props.locked === null) {
            this.add()
        }
    }
    handleValueChange(title, value) {
        if (title === 'QTY') {
            if (this.state.quantities.includes(value)) {
                this.setState({quantity: value})
            }
        }
        else {
            if (this.state.sizes.includes(value)) {
                this.setState({
                    size: value,
                    stock: this.products[this.props.sort - 1].stock.filter(s => s.size === (value))[0].stock
                })
            }
        }
    }
    add() {
        axios.get(url, {
            withCredentials: true,
            params: {
                ADD: '',
                ID: this.props.id,
                QUANTITY: this.state.quantity,
                SIZE: this.state.size
            },
            credentials: 'same-origin'
        })
        .then(response => response.data)
        .then((data) => {
            var add = document.getElementsByClassName('Add')[0];
            add.innerHTML = data;
            setTimeout(() => {
                add.innerHTML = 'Add to Cart';
            }, 5000)
         })
    }
    render() {
        var form = classNames({
            'Form': true,
            'col': true,
            'relative': true,
            'center': true,
        })
        var add = classNames({
            'Add': true,
            'col': true,
            'relative': true,
            'center': true,
            't3': true,
            'shopmaintext': true,
            'textcenter': true,
            'click': this.props.locked === null,
            'quick': true,
            'scale': this.props.locked === null
        })
        var infocontainer = classNames({
            'InfoContainer': true,
            'cover': true,
            'row': true,
            'relative': true,
            'center': true,
        })
        var qty = this.props.locked !== null ? null : this.state.quantities ?
        <FormDropdown   title = {'QTY'}
                        value = {this.state.quantity}
                        options = {this.state.quantities}
                        handleValueChange = {this.handleValueChange}>
        </FormDropdown> : null
        var size = this.props.locked !== null ? null : this.state.sizes ?
        <FormDropdown   title = {'SIZE'}
                        value = {this.state.size}
                        options = {this.state.sizes}
                        handleValueChange = {this.handleValueChange}>
         </FormDropdown>: null
        if (this.props.page !== 'SHOP') {
            return null;
        }
        else {
            return (
                <div className = {form}>
                    <div className = {infocontainer}>
                        {qty}
                        {size}
                    </div>
                    <div className = {add} onClick = {this.handleAdd}>
                        {this.props.locked === null ? this.state.stock > 0 ? 'Add to Cart' : 'OUT OF STOCK' : 'LOCKED'}
                    </div>
                </div>
            )
        }
    }
}
class FormDropdown extends React.Component {
    constructor(props) {
        super(props);
        this.dropdown = this.dropdown.bind(this);
        this.handleValueChange = this.handleValueChange.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.wrapperRef = React.createRef();
        this.state = {
            clicked: false
        }
    }
    handleValueChange(e, title, newValue) {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        e.preventDefault();
        this.props.handleValueChange(title, newValue);
        this.setState({clicked: false})
    }
    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }
    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.setState({clicked: false})
        }
    }
    dropdown() {
        if (this.state.clicked) {
            this.setState({clicked: false})
        }
        else {
            this.setState({clicked: true})
        }
    }
    render() {
        var info = classNames({
            'Info': true,
            'col': true,
            'relative': true,
            'center': true,
        })
        var infotext = classNames({
            'InfoText': true,
            'cover': true,
            'shopmaintext': true,
            't3': true,
            'textcenter': true,
        })
        var infoedit = classNames({
            'InfoEdit': true,
            'cover': true,
            'textcenter': true,
            'row': true,
            'relative': true,
            'center': true
        })
        var infovalue = classNames({
            'InfoValue': true,
            'shopmaintext': true,
            'cover': true,
            't2' : true,
            'textcenter': true,
            'row': true,
            'center': true,
            'relative': true,
            'click': true,
            'quick': true,
            'scale': true,
        })
        var infodropdownoptions = classNames({
            'InfoDropdownOptions': true,
            'absolute': true,
            'quick': true
        }) 
        var infodropdownoption = classNames({
            'InfoDropdownOption': true,
            'shopmaintext': true,
            't3' : true,
            'textcenter': true,
            'click': true,
        })
        var infodropdownimage = classNames({
            'InfoDropdownImage': true,
        })
        var options = this.state.clicked ? 
        <div className = {infodropdownoptions}>
            {this.props.options.map((o) => (
                <div key = {o} className = {infodropdownoption} onClick = {(e) => this.handleValueChange(e, this.props.title, o)}>
                    {o}
                </div>
            ))}
        </div>:null
        return (
            <div className = {info} ref={this.wrapperRef}>
                <div className = {infotext}>
                    {this.props.title}
                </div>
                <div className = {infoedit}>
                    <div className = {infovalue} onClick = {(e) => this.dropdown()}>
                        {this.props.value}
                        <div className = {infodropdownimage}></div>
                    </div>
                    {options}
                </div>
            </div>
        )
    }
}
class Checkout extends React.Component {
    constructor(props) {
        super(props);
        this.getCart = this.getCart.bind(this);
        this.round = this.round.bind(this);
        this.total = 0;
        this.sessid = '';
        this.state = {
            errors: ''
        }
    }
    round(value, decimals) {
        return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
    }  
    getCart() {
        axios.get(url, {
            withCredentials: true,
            params: {
                GETCART: ''
            },
            credentials: 'same-origin'
        })
        .then(response => response.data)
        .then((data) => {
            this.total = Number(data[1].toFixed(2));
            this.sessid = data[2];
         })
    }
    componentDidMount() {
        this.getCart();
    }
    componentDidUpdate(prevProps) {
        if (this.props.page !== prevProps.page && this.props.page === 'CART') {
            this.getCart()
        }
        if (this.props.items !== prevProps.items) {
            this.getCart()
        }
    }
    createOrder(data, actions) {
        return actions.order.create({
            purchase_units: [
                {
                    custom_id: this.sessid,
                    amount: {
                        currency_code: 'USD',
                        value: this.round((this.total), 2),
                        breakdown: {
                            item_total: {
                                currency_code: 'USD',
                                value: this.round((this.total), 2)
                            }
                        }
                    },
                },
            ],
        });
    }
    onSuccess(details, data) {
        this.props.handleSuccess();
    }
    onApprove(data, actions) {
        return actions.order.capture();
    }
    onShippingChange(data, actions) {
        var shippingcost = null;
        var discountcost = null;
        var totalcost = null;
        if (data.shipping_address.country_code !== 'US') {
            discountcost = discount ? discount.valid === true ? discount.type === 'Percentage' ? ((parseFloat(discount.value)/100).toFixed(2) * this.total).toFixed(2) : parseFloat(discount.value).toFixed(2) : 0 : 0;
            shippingcost = shippingdata[1].type === 'Percentage' ? ((parseFloat(shippingdata[1].value)/100).toFixed(2) * this.total).toFixed(2) : parseFloat(shippingdata[1].value).toFixed(2);
            totalcost = this.round(((parseFloat(this.total) + parseFloat(shippingcost) - parseFloat(discountcost)).toFixed(2)), 2);
            return actions.order.patch([
                {
                    op: 'replace',
                    path: '/purchase_units/@reference_id==\'default\'/amount',
                    value: {
                        currency_code: 'USD',
                        value: totalcost,
                        breakdown: {
                            item_total: {
                                currency_code: 'USD',
                                value: this.round((parseFloat(this.total) - parseFloat(discountcost)), 2)
                            },
                            shipping: {
                                currency_code: 'USD',
                                value: shippingcost
                            }
                        }
                    }
                }
            ]);
        }
        else {
            discountcost = discount ? discount.valid === true ? discount.type === 'Percentage' ? ((parseFloat(discount.value)/100).toFixed(2) * this.total).toFixed(2) : parseFloat(discount.value).toFixed(2) : 0 : 0;
            shippingcost = shippingdata[0].type === 'Percentage' ? ((parseFloat(shippingdata[0].value)/100).toFixed(2) * this.total).toFixed(2) : parseFloat(shippingdata[0].value).toFixed(2);
            totalcost = this.round(((parseFloat(this.total) + parseFloat(shippingcost) - parseFloat(discountcost)).toFixed(2)), 2);
            return actions.order.patch([
                {
                    op: 'replace',
                    path: '/purchase_units/@reference_id==\'default\'/amount',
                    value: {
                        currency_code: 'USD',
                        value: totalcost,
                        breakdown: {
                            item_total: {
                                currency_code: 'USD',
                                value: this.round((parseFloat(this.total) - parseFloat(discountcost)), 2)
                            },
                            shipping: {
                                currency_code: 'USD',
                                value: shippingcost
                            }
                        }
                    }
                }
            ]);
        }
    }
    render() {
        var checkout = classNames({
            'Checkout': true,
            'relative': true,
            'click': true,
            'quick': true,
            'scale': true
        })
        var checkouterrors = classNames({
            'CheckoutErrors': true,
            't3': true,
            'shopmaintext': true,
        })
        return (
            <div className = {checkout}>
                <PayPalButton 
                    createOrder={(data, actions) => this.createOrder(data, actions)} 
                    onApprove={(data, actions) => this.onApprove(data, actions)}
                    onShippingChange={(data, actions) => this.onShippingChange(data, actions)}
                    onSuccess={(details, data) => this.onSuccess(details, data)}
                    style={{
                        layout: 'horizontal',
                        color: 'white',
                        shape: 'rect',
                        label: 'paypal',
                        tagline: false
                    }}
                    options={{
                        clientId: "AW83mNQbF_FVH60G7sexuMEhcSn8yVDbcCxr-KZznFV0b6oI9ibipCEcofqQH-CE8xQh9yjuUullz90_"
                    }}
                />
                <div className = {checkouterrors}>
                    {this.state.errors}
                </div>
            </div>
        )
    }
}
class Thanks extends React.Component {
    constructor(props) {
        super(props);
        this.handleBackClick = this.handleBackClick.bind(this);
    }
    handleBackClick() {
        this.props.handlePageChange('SELECT');
    }
    componentDidUpdate() {
        if (this.props.page === 'THANKS') {
            document.documentElement.style.setProperty('--flicker', 'none');
        }
    }
    render() {
        var text = 'Check your email for the PayPal receipt. Thank you for shopping with F1LTHY!';
        var thankscontainer = classNames({
            'ThanksContainer': true,
            'col': true,
            'start': true,
        })
        var thanksbar = classNames({
            'ThanksBar': true,
            'row': true,
            'relative': true,
            'around': true,
        })
        var thankstoggle = classNames({
            'ThanksToggle': true,
            'inversetext': true,
            't4': true,
            'click': true,
            'quick': true,
            'scale': true,
        })
        var thankstext = classNames({
            'ThanksText': true,
            'relative': true,
            'col': true,
            'start': true,
            'inversetext': true,
            'textcenter': true,
            't2': true,
        })
        var thanks = classNames({
            'Thanks': true,
            'fullscreen': true,
            'container': true,
            'col': true,
            'center': true,
        })
        if (this.props.page !== 'THANKS') {
            return null;
        }
        else {
            return (
                <div className = {thanks}>
                    <div className = {thankscontainer}>
                        <div className = {thanksbar}>
                            <div className = {thankstoggle} onClick = {this.handleBackClick}>
                                BACK
                            </div>
                        </div>
                        <div className = {thankstext}>
                            {text}
                        </div>
                    </div>
                </div>
            )
        }
        
    }
}
class Backend extends React.Component {
    constructor(props) {
        super(props);
        this.loggedin = false;
        this.handleLoginChange = this.handleLoginChange.bind(this);
        this.handleDashboardClick = this.handleDashboardClick.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        this.checkLogin = this.checkLogin.bind(this);
        this.state = {
            loggedin: false,
            ismounted: false,
            dashboarditem: 'Orders',
        }
    }
    handleLoginChange(value) {
        this.props.handleLoginChange(value);
    }
    handleDashboardClick(title) {
        this.setState({
            dashboarditem: title
        })
        this.props.handlePageChange(title.toUpperCase());
    }
    handleLogout() {
        axios.get(url, {
            withCredentials: true,
            params: {
                LOGOUT: ''
            },
            credentials: 'same-origin',
        })
        .then(response => response.data)
        .then(() => {
            this.props.handleLoginChange(false);
        })
    }
    checkLogin() {
        axios.get(url, {
            withCredentials: true,
            params: {
                CHECKLOGIN: ''
            },
            credentials: 'same-origin',
        })
        .then(response => response.data)
        .then((data) => {
            if (this.state.ismounted) {
                if (data) {
                    this.setState({
                        loggedin: true
                    })
                }
                else {
                    this.setState({
                        loggedin: false
                    })
                }
            }
        })
    }
    componentDidMount() {
        this.setState({
            ismounted: true
        }, function() {
            this.checkLogin();
        })
    }
    componentWillUnmount() {
        this.setState({
            ismounted: false
        })
    }
    render() {
        var backend = classNames({
            'Backend': true,
            'col': true,
            'center': true,
            'fullscreen': true,
        })
        var dashboard = classNames({
            'Dashboard': true,
            'col': true,
            'center': true,
            'cover': true
        })
        var dashboardbar = classNames({
            'DashboardBar': true,
            'row': true,
            'start': true,
            'relative': true,
        })
        var logout = classNames({
            'Logout': true,
            'backendrounded': true,
            'backendbutton': true,
            'backendmaintext': true,
            'relative': true,
            't1': true,
            'click': true,
            'quick': true
        })
        var login = this.state.loggedin === false ?
            <Login handleLoginChange = {this.handleLoginChange}/> : null
        var Dashboard = this.state.loggedin !== false ?
        <div className = {dashboard}>
            <div className = {dashboardbar}>
                <DashboardItem  clicked = {this.state.dashboarditem}
                                title = {'Orders'}
                                handleClick = {this.handleDashboardClick}/>
                <DashboardItem  clicked = {this.state.dashboarditem}
                                title = {'Inventory'}
                                handleClick = {this.handleDashboardClick}/>
                <DashboardItem  clicked = {this.state.dashboarditem}
                                title = {'Music'}
                                handleClick = {this.handleDashboardClick}/>
                <DashboardItem  clicked = {this.state.dashboarditem}
                                title = {'Settings'}
                                handleClick = {this.handleDashboardClick}/>
            </div>
            <Orders page = {this.props.page}
                    handleLoginChange = {this.handleLoginChange}/>
            <Inventory  windowratio = {this.props.windowratio}
                        page = {this.props.page}/>
            <BackendMusic   windowratio = {this.props.windowratio}
                            page = {this.props.page}/>
            <Settings   windowratio = {this.props.windowratio}
                        page = {this.props.page}
                        lockdate = {this.props.lockdate}/>
            <div className = {logout} onClick = {this.handleLogout}>
                Logout
            </div>
        </div> : null
        return (
            <div className = {backend}>
                {login}
                {Dashboard}
            </div>
        )
    }
}
class DashboardItem extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick(e) {
        this.props.handleClick(this.props.title)
    }
    render() {
        var dashboarditem = classNames({
            'DashboardItem': this.props.clicked !== this.props.title,
            'DashboardItemHighlighted': this.props.clicked === this.props.title,
            'backendbutton': this.props.clicked === this.props.title,
            'backendrounded': true,
            'backendmaintext': true,
            't2': true,
            'textcenter': true,
            'click': true,
            'quick': true
        })
        return(
            <div className = {dashboarditem} onClick = {(e) => (this.handleClick(e))}>
                {this.props.title}
            </div>
        )
    }
}
class Orders extends React.Component {
    constructor(props) {
        super(props);
        this.handleTypeClick = this.handleTypeClick.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.getOrders = this.getOrders.bind(this);
        this.state = {
            orders: [],
            type: 'To-Do'
        }
    }
    handleTypeClick(type) {
        this.setState({
            type: type
        })
        this.getOrders(type);
    }
    handleStatusChange(id, status, tracking, send) {
        axios.get(url, {
            withCredentials: true,
            params: {
                UPDATEORDERSTATUS: '',
                ID: id,
                STATUS: status,
                TRACKING: tracking,
                SEND: send
            },
            credentials: 'same-origin',
        })
        .then(response => response.data)
        .then((data) => {
            if (data) {
                this.getOrders(this.state.type);
            }
        })
    }
    getOrders(type) {
        axios.get(url, {
            withCredentials: true,
            params: {
                GETORDERS: '',
                TYPE: type
            },
            credentials: 'same-origin',
        })
        .then(response => response.data)
        .then((data) => {
            this.setState({orders: data});
        })
    }
    componentDidMount() {
        this.getOrders('To-Do');
    }
    render() {
        var orders = classNames({
            'Orders': true,
            'backendrounded': true,
            'col': true,
            'start': true,
            'relative': true,
            'cover': true,
        })
        var ordertypes = classNames({
            'OrderTypes': true,
            'row': true,
            'start': true,
            'relative': true,
        })
        var orderslist = classNames({
            'OrdersList': true,
            'col': true,
            'start': true,
            'relative': true,
            'cover': true,
            'click': true,
        })
        if (this.props.page !== 'ORDERS') {
            return null;
        }
        else {
            var orderlist = (this.state.orders ? this.state.orders.map((i) => (
                <CSSTransition
                    key = {i.id}
                    classNames = "fade"
                    timeout = {{ enter: 200, exit: 200 }}>
                    <Order  key = {i.id} 
                            id = {i.id} 
                            address = {i.address}
                            email = {i.email}
                            info = {i.info}
                            name = {i.name}
                            status = {i.status}
                            time = {i.time}
                            shippedtime = {i.shippedtime}
                            total = {i.total}
                            txnid = {i.txnid}
                            handleStatusChange = {this.handleStatusChange}/>
                </CSSTransition>
            )):null)
            return(
            <div className = {orders}>
                <div className = {ordertypes}>
                    <OrderType  clicked = {this.state.type}
                                title = {'To-Do'}
                                handleClick = {this.handleTypeClick}/>
                    <OrderType  clicked = {this.state.type}
                                title = {'Shipped'}
                                handleClick = {this.handleTypeClick}/>
                    <OrderType  clicked = {this.state.type}
                                title = {'All'}
                                handleClick = {this.handleTypeClick}/>
                </div>
                <div className = {orderslist}>
                    <TransitionGroup component = {null}>
                        {orderlist}
                    </TransitionGroup>
                </div>
            </div>
            )
        }
    }
}
class Order extends React.Component {
    constructor(props) {
        super(props);
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.handleStatusSubmit = this.handleStatusSubmit.bind(this);
        this.handleTrackingChange = this.handleTrackingChange.bind(this);
        this.handleSendChange = this.handleSendChange.bind(this);
        this.state = {
            value: this.props.status,
            tracking: '',
            send: true
        }
    }
    handleStatusChange(value) {
        this.setState({value: value});
    }
    handleStatusSubmit(value) {
        this.props.handleStatusChange(this.props.txnid, value, this.state.tracking, this.state.send);
    }
    handleTrackingChange(event) {
        this.setState({tracking: event.target.value});
    }
    handleSendChange(value) {
        this.setState({send: value});
    }
    render() {
        var order = classNames({
            'Order': true,
            'backendrounded': true,
            'col': true,
            'start': true,
            'relative': true
        })
        var orderinfo = classNames({
            'OrderInfo': true,
            'row': true,
            'start': true,
            'relative': true
        })
        var ordername = classNames({
            'OrderName': true,
            'backendmaintext': true,
            't2': true
        })
        var orderaddress = classNames({
            'OrderAddress': true,
            'backendmaintext': true,
            't3': true
        })
        var ordertime = classNames({
            'OrderTime': true,
            'backendmaintext': true,
            't4': true
        })
        var orderemail = classNames({
            'OrderEmail': true,
            'backendmaintext': true,
            't4': true
        })
        var ordertxnid = classNames({
            'OrderTxnID': true,
            'backendmaintext': true,
            't4': true
        })
        var orderitems = classNames({
            'OrderItems': true,
            'row': true,
            'start': true,
            'relative': true
        })
        var orderitem = classNames({
            'OrderItem': true,
            'col': true,
            'start': true,
            'relative': true
        })
        var orderitemimage = classNames({
            'OrderItemImage': true,
        })
        var orderitemtext = classNames({
            'OrderItemText': true,
            'backendmaintext': true,
            't3': true,
            'textcenter': true
        })
        var buyerinfo = classNames({
            'BuyerInfo': true,
            'col': true,
            'start': true,
            'relative': true
        })
        var orderactions = classNames({
            'OrderActions': true,
            'cover': true,
            'col': true,
            'start': true,
            'relative': true
        })
        var orderstatus = classNames({
            'OrderStatus': true,
            'row': true,
            'start': true,
            'relative': true,
        })
        var orderstatustext = classNames({
            'OrderStatusText': true,
            'backendmaintext': true,
            't2': true,
            'textcenter': true,
        })
        var ordertracking = classNames({
            'OrderTracking': true,
            'row': true,
            'start': true,
            'relative': true
        })
        var ordertrackingtext = classNames({
            'OrderTrackingText': true,
            'backendmaintext': true,
            't2': true,
        })
        var ordertrackinginput = classNames({
            'OrderTrackingInput': true,
            'backendmaintext': true,
            't2': true,
        })
        var ordertrackingvalue = classNames({
            'OrderTrackingValue': true,
        })
        var orderstatussubmit = classNames({
            'OrderStatusSubmit': true,
            'backendbutton': true,
            'backendrounded': true,
            'backendmaintext': true,
            't2': true,
            'textcenter': true,
        })
        var ordersend = classNames({
            'OrderSend': true,
            'backendslider': true,
            'row': true,
            'start': true,
            'relative': true
        })
        var ordersendtext = classNames({
            'OrderSendText': true,
            'backendmaintext': true,
            't2': true,
        })
        var items = this.props.info ? 
        <div className = {orderitems}>
            {this.props.info.map((i, index) => (
                <div key = {index} className = {orderitem}>
                    <div className = {orderitemimage} style = {{backgroundImage: "url(" + process.env.PUBLIC_URL + "/images/" + i.image}}>
                    </div>
                    <div className = {orderitemtext}>
                        {i.size} x{i.quantity}
                    </div>
                </div>
            ))}
        </div>
        : null
        var input = this.state.value === 'Shipped' && this.state.value !== this.props.status ? 
        <div className = {ordertracking}>
            <div className = {ordertrackingtext}>
                Tracking: 
            </div>
            <div className = {ordertrackingvalue}>
                <input className = {ordertrackinginput} value = {this.state.tracking} onChange = {this.handleTrackingChange}></input>
            </div>
        </div>
        : null
        var send = this.state.value === 'Shipped' && this.state.value !== this.props.status ? 
        <div className = {ordersend}>
            <div className = {ordersendtext}>
                Send Email?
            </div>
            <Slider value = {this.state.send}
                handleChange = {this.handleSendChange}/>
        </div>
        : null
        var submit = this.state.value !== this.props.status ?
        <div className = {orderstatussubmit}  onClick = {(e) => this.handleStatusSubmit(this.state.value)}>
            Confirm
        </div>
        : null
        return(
            <div className = {order}>
                <div className = {orderinfo}>
                    <div className = {buyerinfo}>
                        <div className = {ordername}>
                            {this.props.name}
                        </div>
                        <div className = {orderaddress}>
                            {this.props.address}
                        </div>
                        <div className = {ordertime}>
                            Created: {new Date(this.props.time).toLocaleString('en-US')}
                        </div>
                        <div className = {ordertime}>
                            Shipped: {this.props.shippedtime !== null ? new Date(this.props.shippedtime).toLocaleString('en-US'): 'N/A'}
                        </div>
                        <div className = {orderemail}>
                            {this.props.email}
                        </div>
                        <div className = {ordertxnid}>
                            ID: {this.props.txnid}
                        </div>
                        {items}
                    </div>
                    <div className = {orderactions}>
                        <div className = {orderstatus}>
                            <div className = {orderstatustext}>
                                Status: 
                            </div>
                            <BackendDropdown    value = {this.state.value}
                                                options = {['Incomplete', 'Shipped']}
                                                handleValueChange = {this.handleStatusChange}/>
                        </div>
                        {input}
                        {send}
                        {submit}
                    </div>
                </div>
            </div>
        )
    }
}
class BackendDropdown extends React.Component {
    constructor(props) {
        super(props);
        this.dropdown = this.dropdown.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.wrapperRef = React.createRef();
        this.state = {
            clicked: false,
        }
    }
    handleValueChange(e, value) {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        e.preventDefault();
        this.props.handleValueChange(value);
        this.setState({clicked: false})
    }
    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }
    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.setState({clicked: false})
        }
    }
    dropdown() {
        if (this.state.clicked) {
            this.setState({clicked: false})
        }
        else {
            this.setState({clicked: true})
        }
    }
    render() {
        var backenddropdownoptions = classNames ({
            'BackendDropdownOptions': true,
            'col': true,
            'start': true,
            'relative': true
        })
        var backenddropdownoption = classNames({
            'BackendDropdownOption': true,
            'backendmaintext': true,
            't2': true,
            'textcenter': true,
        })
        var backenddropdown = classNames({
            'BackendDropdown': true,
            'click': true,
            'quick': true
        })
        var backenddropdownvalue = classNames({
            'BackendDropdownValue': true,
            'backendmaintext': true,
            't2' : true,
            'textcenter': true,
            'row': true,
            'start': true,
            'relative': true,
        })
        var backenddropdownimage = classNames({
            'BackendDropdownImage': true
        })
        var options = this.state.clicked ? 
        <div className = {backenddropdownoptions}>
            {this.props.options.map((o) => (
                <div key = {o} className = {backenddropdownoption}  onClick = {(e) => this.handleValueChange(e, o)}>
                    {o}
                </div>
            ))}
        </div>:null
        return (
            <div className = {backenddropdown} ref={this.wrapperRef}>
                <div className = {backenddropdownvalue} onClick = {(e) => this.dropdown()}>
                    {this.props.value}
                    <div className = {backenddropdownimage}></div>
                </div>
                {options}
            </div>
        )
    }
}
class OrderType extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick(e) {
        this.props.handleClick(this.props.title)
    }
    render() {
        var ordertype = classNames({
            'OrderType': this.props.clicked !== this.props.title,
            'OrderTypeHighlighted': this.props.clicked === this.props.title,
            'backendrounded': true,
            'backendmaintext': true,
            't2': true,
            'textcenter': true,
            'click': true,
            'quick': true
        })
        return(
            <div className = {ordertype} onClick = {(e) => (this.handleClick(e))}>
                {this.props.title}
            </div>
        )
    }
}
class Inventory extends React.Component {
    constructor(props) {
        super(props);
        this.getInfo = this.getInfo.bind(this);
        this.handleItemClick = this.handleItemClick.bind(this);
        this.handleItemClickExit = this.handleItemClickExit.bind(this);
        this.handleBackClick = this.handleBackClick.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
        this.handleAddExit = this.handleAddExit.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        this.handleStockEdit = this.handleStockEdit.bind(this);
        this.handleSizeEdit = this.handleSizeEdit.bind(this);
        this.handleSizeAdd = this.handleSizeAdd.bind(this);
        this.handleSizeRemove = this.handleSizeRemove.bind(this);
        this.handleDetailEdit = this.handleDetailEdit.bind(this);
        this.handleReplaceImage = this.handleReplaceImage.bind(this);
        this.handleRemoveImage = this.handleRemoveImage.bind(this);
        this.handleAddImage = this.handleAddImage.bind(this);
        this.handleItemSortChange = this.handleItemSortChange.bind(this);
        this.holdTime = 500;
        this.swipeTime = 50;
        this.state = {
            products: [],
            clicked: 0,
            held: 0,
            clicktime: 0,
            imageerror: ''
        }
    }
    getInfo() {
        axios.get(url, {
            withCredentials: true,
            params: {
                GETALL: '',
                BACKEND: ''
            },
            credentials: 'same-origin'
        })
        .then(response => response.data)
        .then((data) => {
            this.setState({
                products: data
            })
        })
    }
    handleItemClick(id) {
        if (!isMobile) {
            this.setState({
                clicktime: Date.now()
            }, function() {
                setTimeout(() => {
                    if (this.state.held === 0 && this.state.clicked === 0) {
                        this.setState({
                            held: id
                        })
                    }
                }, this.holdTime);
            })
        }
        else {
            this.setState({
                clicktime: Date.now()
            })
        }
    }
    handleItemClickExit(id) {
        if (!isMobile) {
            if ((Date.now() - this.state.clicktime) < this.holdTime) {
                if (this.state.held === 0) {
                    this.setState({
                        clicked: id
                    })
                }
            }
            else {
                if (this.state.held === 0) {
                    this.setState({
                        held: id
                    })
                }
            }
        }
        else {
            var gestureTime = Date.now() - this.state.clicktime;
            if (gestureTime < this.holdTime) {
                if (gestureTime > this.swipeTime) {
                    
                }
                else {
                    if (this.state.held === 0) {
                        this.setState({
                            clicked: id
                        })
                    }
                }
            }
            else {
                if (this.state.held === 0) {
                    this.setState({
                        held: id
                    })
                }
            }
        }
    }
    handleBackClick() {
        this.setState({
            clicked: 0
        })
    }
    handleAdd(id) {
        this.setState({
            clicktime: Date.now()
        })
    }
    handleAddExit(id) {
        if (!isMobile) {
            if ((Date.now() - this.state.clicktime) < this.holdTime) {
                axios.get(url, {
                    withCredentials: true,
                    params: {
                        ADDPRODUCT: '',
                    },
                    credentials: 'same-origin'
                })
                .then(response => response.data)
                .then((data) => {
                    if (data) {
                        this.getInfo();
                    }
                })
            }
        }
        else {
            var gestureTime = Date.now() - this.state.clicktime;
            if (gestureTime < this.holdTime) {
                if (gestureTime < this.swipeTime) {
                    axios.get(url, {
                        withCredentials: true,
                        params: {
                            ADDPRODUCT: '',
                        },
                        credentials: 'same-origin'
                    })
                    .then(response => response.data)
                    .then((data) => {
                        if (data) {
                            this.getInfo();
                        }
                    })
                }
            }
        }
    }
    handleRemove(id) {
        axios.get(url, {
            withCredentials: true,
            params: {
                REMOVEPRODUCT: '',
                ID: id
            },
            credentials: 'same-origin'
        })
        .then(response => response.data)
        .then((data) => {
            if (data) {
                this.getInfo();
            }
        })
    }
    handleStockEdit(id, size, value) {
        axios.get(url, {
            withCredentials: true,
            params: {
                UPDATESTOCK: '',
                ID: id,
                SIZE: size,
                VALUE: value,
            },
            credentials: 'same-origin'
        })
        .then(response => response.data)
        .then((data) => {
            if (data) {
                this.getInfo();
            }
        })
    }
    handleSizeEdit(id, size, value) {
        axios.get(url, {
            withCredentials: true,
            params: {
                UPDATESIZE: '',
                ID: id,
                SIZE: size,
                VALUE: value,
            },
            credentials: 'same-origin'
        })
        .then(response => response.data)
        .then((data) => {
            if (data) {
                this.getInfo();
            }
        })
    }
    handleSizeAdd(id) {
        axios.get(url, {
            withCredentials: true,
            params: {
                ADDSIZE: '',
                ID: id,
            },
            credentials: 'same-origin'
        })
        .then(response => response.data)
        .then((data) => {
            if (data) {
                this.getInfo();
            }
        })
    }
    handleSizeRemove(id, size) {
        axios.get(url, {
            withCredentials: true,
            params: {
                REMOVESIZE: '',
                ID: id,
                SIZE: size,
            },
            credentials: 'same-origin'
        })
        .then(response => response.data)
        .then((data) => {
            if (data) {
                this.getInfo();
            }
        })
    }
    handleDetailEdit(id, category, value) {
        axios.get(url, {
            withCredentials: true,
            params: {
                UPDATEDETAILS: '',
                ID: id,
                CATEGORY: category,
                VALUE: value,
            },
            credentials: 'same-origin'
        })
        .then(response => response.data)
        .then((data) => {
            if (data) {
                this.getInfo();
            }
        })
    }
    handleReplaceImage(id, file, image) {
        const formData = new FormData();
        formData.set("REPLACEIMAGE", true);
        formData.set("IMAGENAME", image);
        formData.set("PRODUCTID", id);
        formData.append("IMAGE", file);
        axios({
            method: 'post',
            url: url,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(response => response.data)
        .then((data) => {
            if (data === '') {
                this.getInfo();
            }
            this.setState({imageerror: data})
        })
    }
    handleRemoveImage(image, id) {
        axios.get(url, {
            withCredentials: true,
            params: {
                REMOVEIMAGE: '',
                ID: id,
                IMAGE: image,
            },
            credentials: 'same-origin'
        })
        .then(response => response.data)
        .then((data) => {
            if (data) {
                this.getInfo();
            }
        })
    }
    handleAddImage(id) {
        axios.get(url, {
            withCredentials: true,
            params: {
                ADDIMAGE: '',
                ID: id,
            },
            credentials: 'same-origin'
        })
        .then(response => response.data)
        .then((data) => {
            if (data) {
                this.getInfo();
            }
        })
    }
    handleItemSortChange(target) {
        if (this.state.held !== 0) {
            axios.get(url, {
                withCredentials: true,
                params: {
                    ITEMSORTCHANGE: '',
                    ID: this.state.held,
                    TARGET: target,
                },
                credentials: 'same-origin'
            })
            .then(response => response.data)
            .then((data) => {
                if (data) {
                    this.getInfo();
                    this.setState({
                        held: 0
                    })
                }
            })
        }
    }
    componentDidMount() {
        this.getInfo()
    }
    render() {
        var inventory = classNames({
            'Inventory': true,
            'backendrounded': true,
            'row': true,
            'start': true,
            'relative': true,
            'cover': true,
        })
        var inventoryitems = classNames({
            'InventoryItems': true,
            'row': this.props.windowratio > 1,
            'col': this.props.windowratio < 1,
            'start': this.props.windowratio > 1,
            'center': this.props.windowratio < 1,
            'relative': true,
            'cover': true,
            'click': true,
        })
        var inventorybackbutton = classNames({
            'InventoryBackButton': true,
            'backendbutton': true,
            'backendrounded': true,
            'backendmaintext': true,
            't4': true,
            'textcenter': true,
            'relative': true,
            'click': true,
            'quick': true,
            'scale': true
        })
        var inventoryitemcontainer = classNames({
            'InventoryItemContainer': true,
            'InventoryItemContainerClicked': this.state.clicked !== 0,
            'row': this.props.windowratio > 1,
            'col': this.props.windowratio < 1,
            'start': this.props.windowratio > 1,
            'center': this.props.windowratio < 1,
            'relative': true
        })
        var backbutton = this.state.clicked !== 0 ?
            <div className = {inventorybackbutton} onClick = {(e) => this.handleBackClick()}>
                Back
            </div>
        : null
        var items = this.state.products ? this.state.products.map((i) => (
                <CSSTransition  key = {i.id}
                                classNames = "fade"
                                timeout = {{ enter: 200, exit: 200 }}>
                    <div className = {inventoryitemcontainer} key = {i.id}>
                        <InventoryItemPlace sort = {i.sort}
                                            held = {this.state.held}
                                            clicked = {this.state.clicked}
                                            handleClick = {this.handleItemSortChange}/>
                        <InventoryItem  id = {i.id}
                                        images = {i.images}
                                        name = {i.name}
                                        price = {i.price}
                                        description = {i.description}
                                        options = {i.options}
                                        max = {i.max}
                                        stock = {i.stock}
                                        locked = {i.locked}
                                        hide = {i.hide}
                                        sort = {i.sort}
                                        products = {this.state.products}
                                        clicked = {this.state.clicked}
                                        windowratio = {this.props.windowratio}
                                        imageerror = {this.state.imageerror}
                                        handleClick = {this.handleItemClick}
                                        handleClickExit = {this.handleItemClickExit}
                                        handleRemove = {this.handleRemove}
                                        handleStockEdit = {this.handleStockEdit}
                                        handleSizeEdit = {this.handleSizeEdit}
                                        handleSizeAdd = {this.handleSizeAdd}
                                        handleSizeRemove = {this.handleSizeRemove}
                                        handleDetailEdit = {this.handleDetailEdit}
                                        handleReplaceImage = {this.handleReplaceImage}
                                        handleRemoveImage = {this.handleRemoveImage}
                                        handleAddImage = {this.handleAddImage}/>
                    </div>
                </CSSTransition>
            ))
        : null
        var add = this.state.products.length !== 0 ? 
            <div className = {inventoryitemcontainer}>
                <InventoryItemPlace sort = {this.state.products.length + 1}
                                    held = {this.state.held}
                                    clicked = {this.state.clicked}
                                    handleClick = {this.handleItemSortChange}/>
                <InventoryItem  key = {(Math.max(...this.state.products.map(o => o.id)) + 1)}
                                id = {(Math.max(...this.state.products.map(o => o.id)) + 1)}
                                images = {['newpreview0.png']}
                                name = {''}
                                price = {''}
                                description = {''}
                                options = {[]}
                                max = {0}
                                stock = {[]}
                                locked = {null}
                                hide = {null}
                                sort = {this.state.products.length + 1}
                                products = {this.state.products}
                                add = {true}
                                clicked = {this.state.clicked}
                                windowratio = {this.props.windowratio}
                                imageerror = {this.state.imageerror}
                                handleClick = {this.handleAdd}
                                handleClickExit = {this.handleAddExit}
                                handleStockEdit = {this.handleStockEdit}
                                handleSizeEdit = {this.handleSizeEdit}
                                handleSizeAdd = {this.handleSizeAdd}
                                handleSizeRemove = {this.handleSizeRemove}
                                handleDetailEdit = {this.handleDetailEdit}
                                handleReplaceImage = {this.handleReplaceImage}
                                handleRemoveImage = {this.handleRemoveImage}
                                handleAddImage = {this.handleAddImage}/>
            </div>
        : null
        if (this.props.page !== 'INVENTORY') {
            return null;
        }
        else {
            return(
            <div className = {inventory}>
                <div className = {inventoryitems}>
                    <TransitionGroup component = {null}>
                        {items}
                    </TransitionGroup>
                    {add}
                </div>
                {backbutton}
            </div>
            )
        }
    }
}
class InventoryItem extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.handleClickExit = this.handleClickExit.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        this.handleReplaceImage = this.handleReplaceImage.bind(this);
        this.handleRemoveImage = this.handleRemoveImage.bind(this);
        this.handleAddImage = this.handleAddImage.bind(this);
        this.handleStockEdit = this.handleStockEdit.bind(this);
        this.handleSizeEdit = this.handleSizeEdit.bind(this);
        this.handleSizeAdd = this.handleSizeAdd.bind(this);
        this.handleSizeRemove = this.handleSizeRemove.bind(this);
        this.handleDetailEdit = this.handleDetailEdit.bind(this);
        this.handleLockedEdit = this.handleLockedEdit.bind(this);
        this.handleHiddenEdit = this.handleHiddenEdit.bind(this);
    }
    handleClick(e) {
        this.props.handleClick(this.props.id);
    }
    handleClickExit(e) {
        this.props.handleClickExit(this.props.id);
    }
    handleRemove(e) {
        if (e) {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            e.preventDefault();
        }
        this.props.handleRemove(this.props.id);
    }
    handleStockEdit(size, value) {
        this.props.handleStockEdit(this.props.id, size, value);
    }
    handleSizeEdit(size, newsize) {
        this.props.handleSizeEdit(this.props.id, size, newsize);
    }
    handleSizeAdd() {
        this.props.handleSizeAdd(this.props.id);
    }
    handleSizeRemove(size) {
        this.props.handleSizeRemove(this.props.id, size);
    }
    handleDetailEdit(category, value) {
        this.props.handleDetailEdit(this.props.id, category, value);
    }
    handleLockedEdit(value) {
        var lockedvalue = value === true ? 'locked' : null
        this.handleDetailEdit('locked', lockedvalue);
    }
    handleHiddenEdit(value) {
        var hiddenvalue = value === true ? 1 : 0
        this.handleDetailEdit('hide', hiddenvalue);
    }
    handleReplaceImage(e, file, image) {
        this.props.handleReplaceImage(this.props.id, file, image);
    }
    handleRemoveImage(image) {
        this.props.handleRemoveImage(image, this.props.id);
    }
    handleAddImage() {
        this.props.handleAddImage(this.props.id);
    }
    render() {
        var inventoryitem = classNames({
            'InventoryItem': true,
            'backendrounded': true,
            'cover': true,
            'col': this.props.clicked !== this.props.id,
            'row': this.props.clicked === this.props.id,
            'start': true,
            'relative': true,
        })
        var inventoryitemdisplay = classNames({
            'InventoryItemDisplay': true,
        })
        var inventoryiteminfo = classNames({
            'InventoryItemInfo': true,
            'row': this.props.windowratio > 1,
            'col': this.props.windowratio < 1,
            'start': true,
            'relative': true,
        })
        var inventoryitemleftcontainer = classNames({
            'InventoryItemLeftContainer': true,
            'inventoryiteminfocontainer': true,
            'backendrounded': true,
            'cover': true,
            'col': true,
            'start': true,
            'relative': true,
        })
        var inventoryitemdetailcontainer = classNames({
            'InventoryItemDetailContainer': true,
            'inventoryiteminfocontainer': true,
            'backendrounded': true,
            'col': true,
            'start': true,
            'relative': true,
        })
        var inventoryitemstockcontainer = classNames({
            'InventoryItemStockContainer': true,
            'inventoryiteminfocontainer': true,
            'backendrounded': true,
            'col': true,
            'start': true,
            'relative': true,
        })
        var inventoryitemstockeditcontainer = classNames({
            'InventoryItemStockEditContainer': true,
            'row': true,
            'start': true,
            'relative': true,
        })
        var inventoryiteminfotitle = classNames({
            'InventoryItemInfoTitle': true,
            'backendmaintext': true,
            't3': true,
        })
        var inventoryitemoverlay = classNames({
            'InventoryItemOverlay': true,
            'backendrounded': true,
            'cover': true,
            'absolute': true,
            'click': true,
            'quick': true,
        })
        var inventoryitemremove = classNames({
            'InventoryItemRemove': true,
            'mg2': true,
            'absolute': true,
            'click': true,
            'quick': true
        })
        var inventoryitemlocked = classNames({
            'InventoryItemLocked': true,
            'backendslider': true,
            'row': true,
            'start': true,
            'relative': true
        })
        var inventoryitemlockedtext = classNames({
            'InventoryItemLockedText': true,
            'backendmaintext': true,
            't2': true,
        })
        var inventoryitemhidden = classNames({
            'InventoryItemHidden': true,
            'backendslider': true,
            'row': true,
            'start': true,
            'relative': true
        })
        var inventoryitemhiddentext = classNames({
            'InventoryItemHiddenText': true,
            'backendmaintext': true,
            't2': true,
        })
        var inventoryitemimageerror = classNames({
            'InventoryItemImageError': true,
            'backendmaintext': true,
            't2': true,
            'textcenter': true,
        })
        var overlay = this.props.clicked === 0 ? 
            <div className = {inventoryitemoverlay} onMouseDown = {(e) => this.handleClick(e)} onTouchStart = {(e) => this.handleClick(e)} onMouseUp = {(e) => this.handleClickExit(e)} onTouchEnd = {(e) => this.handleClickExit(e)}>
            </div>
        : null
        var image = this.props.clicked === 0 ? 
            <div className = {inventoryitemdisplay} style = {{backgroundImage: "url(" + process.env.PUBLIC_URL + "/images/" + this.props.images.filter(image => image.includes('preview0'))}}>
            </div>
        : null
        var remove = this.props.add !== true && this.props.clicked === 0 ?
        <div className = {inventoryitemremove} onClick = {(e) => this.handleRemove(e)}>
        </div>
        : null
        var sizes = this.props.stock ? 
        this.props.stock.map((i, index) => (
            <CSSTransition  key = {index}
                            classNames = "fade"
                            timeout = {{ enter: 200, exit: 200 }}>
                <InventoryItemStockEdit key = {index}
                                        value = {i.stock}
                                        size = {i.size}
                                        handleSizeEdit = {this.handleSizeEdit}
                                        handleValueEdit = {this.handleStockEdit}
                                        handleRemove = {this.handleSizeRemove}/>
            </CSSTransition>
        ))
        : null
        var info = this.props.clicked === this.props.id ?
            <div className = {inventoryiteminfo}>
                <div className = {inventoryitemleftcontainer}>
                    <InventoryItemImages    windowratio = {this.props.windowratio}
                                            images = {this.props.images}
                                            handleReplaceImage = {this.handleReplaceImage}
                                            handleRemoveImage = {this.handleRemoveImage}
                                            handleAddImage = {this.handleAddImage}/>
                    <div className = {inventoryitemimageerror}>
                        {this.props.imageerror}
                    </div>
                    <div className = {inventoryitemstockcontainer}>
                        <div className = {inventoryiteminfotitle}>
                            Stock
                        </div>
                        <div className = {inventoryitemstockeditcontainer}>
                            <TransitionGroup component = {null}>
                                {sizes}
                            </TransitionGroup>
                            <InventoryItemStockEdit key = {(this.props.stock.length + 1)}
                                                    add = {true}
                                                    addvalue = {'+'}
                                                    handleAdd = {this.handleSizeAdd}/>
                        </div>
                    </div>
                </div>
                <div className = {inventoryitemdetailcontainer}>
                    <InventoryItemDetailEdit    value = {this.props.name}
                                                label = {'Name'}
                                                category = {'name'}
                                                handleSubmit = {this.handleDetailEdit}/>
                    <InventoryItemDetailEdit    value = {this.props.price}
                                                label = {'Price'}
                                                category = {'price'}
                                                handleSubmit = {this.handleDetailEdit}/>
                    <InventoryItemDetailEdit    value = {this.props.description}
                                                label = {'Description'}
                                                category = {'description'}
                                                multiline = {true}
                                                handleSubmit = {this.handleDetailEdit}/>
                    <div className = {inventoryitemlocked}>
                        <div className = {inventoryitemlockedtext}>
                            Locked?
                        </div>
                        <Slider value = {this.props.locked !== null}
                                handleChange = {this.handleLockedEdit}
                        />
                    </div>
                    <div className = {inventoryitemhidden}>
                        <div className = {inventoryitemhiddentext}>
                            Hidden?
                        </div>
                        <Slider value = {this.props.hide !== 0}
                                handleChange = {this.handleHiddenEdit}
                        />
                    </div>
                </div>
            </div>
        : null
        if (this.props.clicked !== 0 && this.props.clicked !== this.props.id) {
            return null;
        }
        else {
            return(
                <div className = {inventoryitem}>
                    {remove}
                    {overlay}
                    {image}
                    {info}
                </div>
            )
        }
    }
}
class InventoryItemPlace extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick() {
        this.props.handleClick(this.props.sort);
    }
    render() {
        var inventoryitemplace = classNames({
            'InventoryItemPlace': true,
            'click': true,
        })
        if (this.props.clicked !== 0 || !this.props.held) {
            return null
        }
        else {
            return (
                <div className = {inventoryitemplace} onClick = {(e) => this.handleClick()}>
            </div>
            )
        }
    }
}
class InventoryItemImages extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.handleHover = this.handleHover.bind(this);
        this.handleReplaceImage = this.handleReplaceImage.bind(this);
        this.handleRemoveImage = this.handleRemoveImage.bind(this);
        this.handleAddImage = this.handleAddImage.bind(this);
        this.handleFile = this.handleFile.bind(this);
        this.input = React.createRef();
        this.state = {
            clicked: 0,
            file: null,
            hover: false,
            uploading: false,
        }
    }
    handleClick(id) {
        this.setState({clicked: id});
    }
    handleHover(value) {
        if (this.state.uploading === false) {
            this.setState({hover: value});
        }
    }
    handleReplaceImage() {
        this.input.click();
        this.setState({uploading: true});
    }
    handleRemoveImage() {
        this.props.handleRemoveImage(this.props.images[this.state.clicked]);
    }
    handleAddImage(id) {
        this.props.handleAddImage();
    }
    handleFile(e) {
        e.stopPropagation();
        e.preventDefault();
        var file = e.target.files[0];
        this.setState({
            file: file,
            hover: false,
            uploading: false
        }, function() {
            this.props.handleReplaceImage(e, this.state.file, this.props.images[this.state.clicked]);
        });
    }
    render() {
        var inventoryitemimagescontainer = classNames({
            'InventoryItemImagesContainer': true,
            'row': true,
            'center': true,
            'relative': true,
        })
        var inventoryitemimagespreviews = classNames({
            'InventoryItemImagesPreviews': true,
            'col': true,
            'start': true,
            'relative': true
        })
        var inventoryitemimage = classNames({
            'InventoryItemImage': true,
            'col': true,
            'center': true,
            'relative': true,
            'click': true,
        })
        var inventoryitemimageoptions = classNames({
            'InventoryItemImageOptions': true,
            'cover': true,
            'col': true,
            'center': true,
            'relative': true
        })
        var inventoryitemimagereplace = classNames({
            'InventoryItemImageReplace': true,
            'inventoryitemimageoption': true,
            'backendrounded': true,
            'backendbutton': true,
            'backendmaintext': true,
            't3': true,
            'textcenter': true,
            'click': true,
        })
        var inventoryitemimageremove = classNames({
            'InventoryItemImageRemove': true,
            'inventoryitemimageoption': true,
            'backendrounded': true,
            'backendbutton': true,
            'backendmaintext': true,
            't3': true,
            'textcenter': true,
            'click': true,
        })
        var inventoryitemimageinput = classNames({
            'InventoryItemImageInput': true,
        })
        var previews = this.props.images ? 
        this.props.images.map((i, index) => (
            <CSSTransition  key = {index}
                            classNames = "fade"
                            timeout = {{ enter: 200, exit: 200 }}>
                <InventoryItemImageEdit key = {index}
                                        id = {index}
                                        image = {i}
                                        clicked = {this.state.clicked}
                                        handleClick = {this.handleClick}>
                </InventoryItemImageEdit>
            </CSSTransition>
        ))
        : null
        var options = this.state.hover === true || this.props.windowratio < 1 ?
            <div className = {inventoryitemimageoptions}>
                <div className = {inventoryitemimagereplace} onClick = {() => this.handleReplaceImage()}>
                    Replace
                    <input className = {inventoryitemimageinput} type="file" name="file" onChange={(e) => this.handleFile(e)} ref = {input => (this.input = input)} />
                </div>
                {this.state.clicked !== 0 && this.state.clicked !== 1 ? 
                    <div className = {inventoryitemimageremove} onClick = {() => this.handleRemoveImage()}>
                        Remove
                    </div>
                : null}
            </div>
        : null
        return (
            <div className = {inventoryitemimagescontainer}>
                <div className = {inventoryitemimagespreviews}>
                    <TransitionGroup component = {null}>
                        {previews}
                    </TransitionGroup>
                    <InventoryItemImageEdit id = {this.props.images.length}
                                            image = {'newpreview0.png'}
                                            clicked = {this.state.clicked}
                                            handleClick = {this.handleAddImage}>
                    </InventoryItemImageEdit>
                </div>
                <div className = {inventoryitemimage} style = {{backgroundImage: this.props.images ? this.state.clicked === 0 ? "url(" + process.env.PUBLIC_URL + "/images/" + this.props.images[0] : "url(" + process.env.PUBLIC_URL + "/images/" + this.props.images[this.state.clicked] : "url(" + process.env.PUBLIC_URL + "/images/placeholder.png"}} onMouseEnter={() => this.handleHover(true)} onMouseLeave={() => this.handleHover(false)}>
                    {options}
                </div>
            </div>
        )
    }
}
class InventoryItemImageEdit extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick() {
        this.props.handleClick(this.props.id);
    }
    render() {
        var inventoryitempreviewimage = classNames({
            'InventoryItemPreviewImage': true,
            'inventoryitemimageoutline': this.props.clicked === this.props.id,
            'click': true,
            'quick': true,
            'scale': true
        })
        return (
            <div key = {this.props.id} id = {this.props.id} className = {inventoryitempreviewimage} style = {{backgroundImage: "url(" + process.env.PUBLIC_URL + "/images/" + this.props.image}} onClick = {() => this.handleClick()}>
            </div>
        )
    }
}
class InventoryItemStockEdit extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleSizeChange = this.handleSizeChange.bind(this);
        this.handleValueChange = this.handleValueChange.bind(this);
        this.handleKey = this.handleKey.bind(this);
        this.wrapperRef = React.createRef();
        this.buttonRef = React.createRef();
        this.state = {
            clicked: false,
            size: this.props.size,
            value: this.props.value
        }
    }
    handleClick(event) {
        if (this.state.clicked === false) {
            this.setState({
                clicked: true
            }, function() {
                this.valueInput.focus();
                this.valueInput.select();
            })
        }
        else if (this.buttonRef && this.buttonRef.current.contains(event.target)) {
            this.handleSubmit();
        }
    }
    handleAdd(e) {
        this.props.handleAdd(this.props.addvalue);
    }
    handleRemove(e) {
        this.props.handleRemove(this.props.size);
    }
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.setState({clicked: false})
        }
    }
    handleSizeChange(event) {
        this.setState({size: event.target.value});
    }
    handleValueChange(event) {
        this.setState({value: event.target.value});
    }
    handleSubmit() {
        if (this.state.size !== this.props.size && parseInt(this.state.value) !== this.props.value) {
            this.props.handleSizeEdit(this.props.size, this.state.size);
            this.props.handleValueEdit(this.state.size, this.state.value);
        }
        else if (this.state.size !== this.props.size) {
            this.props.handleSizeEdit(this.props.size, this.state.size);
        }
        else if (parseInt(this.state.value) !== this.props.value) {
            this.props.handleValueEdit(this.props.size, this.state.value);
        }
        this.setState({clicked: false});
    }
    handleKey(e) {
        if (e.keyCode === 13) {
            this.handleSubmit(e);
        }
    }
    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }
    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }
    render() {
        var inventorystockedit = classNames({
            'InventoryStockEdit': true,
            'InventoryStockEditAdd': this.props.add === true,
            'backendrounded': true,
            'col': true,
            'start': true,
            'relative': true,
            'click': this.props.add === true,
        })
        var inventorystockeditoverlay = classNames({
            'InventoryStockEditOverlay': true,
            'backendrounded': true,
            'cover': true,
            'absolute': true,
            'click': true,
            'quick': true,
        })
        var inventorystockeditremove = classNames({
            'InventoryStockEditRemove': true,
            'mg2': true,
            'absolute': true,
            'click': true,
            'quick': true
        })
        var inventorystockeditsize = classNames({
            'InventoryStockEditSize': true,
            'row': true,
            'center': true,
            'relative': true,
            'click': true
        })
        var inventorystockeditvalue = classNames({
            'InventoryStockEditValue': true,
            'row': true,
            'center': true,
            'relative': true,
            'click': true
        })
        var inventorystockeditsizedisplay = classNames({
            'InventoryStockEditSizeDisplay': true,
            'backendmaintext': true,
            't2': true
        })
        var inventorystockeditvaluedisplay = classNames({
            'InventoryStockEditValueDisplay': true,
            'backendmaintext': true,
            't3': true
        })
        var inventorystockeditsizeinput = classNames({
            'InventoryStockEditInput': true,
            'backendmaintext': true,
            't2': true
        })
        var inventorystockeditvalueinput = classNames({
            'InventoryStockEditInput': true,
            'backendmaintext': true,
            't3': true
        })
        var inventorystockeditbutton = classNames({
            'InventoryStockEditButton': true,
            'backendrounded': true,
            'backendbutton': true,
            'backendmaintext': true,
            'textcenter': true,
            'col': true,
            'center': true,
            'relative': true,
            't4': true,
            'click': true
        })
        var sizedisplay = this.state.clicked === false ? 
            <div className = {inventorystockeditsizedisplay}>
                {this.props.size}
            </div>
        : null
        var sizeinput = this.state.clicked === true ? 
            <input className = {inventorystockeditsizeinput} value = {this.state.size} onChange = {this.handleSizeChange} ref = {input => (this.sizeInput = input)} onKeyDown = {(e) => this.handleKey(e)}></input>
        : null
        var valuedisplay = this.state.clicked === false ? 
            <div className = {inventorystockeditvaluedisplay}>
                {this.props.value}
            </div>
        : null
        var valueinput = this.state.clicked === true ?
            <input className = {inventorystockeditvalueinput} value = {this.state.value} onChange = {this.handleValueChange} ref = {input => (this.valueInput = input)} onKeyDown = {(e) => this.handleKey(e)}></input>
        : null
        var remove = this.state.clicked === false ? 
            <div className = {inventorystockeditremove} onClick = {(e) => this.handleRemove(e)}></div>
        : null
        if (this.props.add === true) {
            return(
                <div className = {inventorystockedit} ref={this.wrapperRef}>
                    <div className = {inventorystockeditoverlay} onClick = {(e) => this.handleAdd(e)}></div>
                </div>
            )
        }
        else {
            return(
                <div className = {inventorystockedit} ref={this.wrapperRef}>
                    {remove}
                    <div className = {inventorystockeditsize}>
                        {sizedisplay}
                        {sizeinput}
                    </div>
                    <div className = {inventorystockeditvalue}>
                        {valuedisplay}
                        {valueinput}
                    </div>
                    <div className = {inventorystockeditbutton} ref={this.buttonRef} onClick = {(e) => this.handleClick(e)}>
                        {this.state.clicked === true ? 'Confirm' : 'Edit'}
                    </div>
                </div>
            )
        }
    }
}
class InventoryItemDetailEdit extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleKey = this.handleKey.bind(this);
        this.wrapperRef = React.createRef();
        this.buttonRef = React.createRef();
        this.state = {
            clicked: false,
            value: this.props.value
        }
    }
    handleClick(event) {
        if (this.state.clicked === false) {
            this.setState({
                clicked: true
            }, function() {
                this.input.focus();
                this.input.select();
            })
        }
        else if (this.buttonRef && this.buttonRef.current.contains(event.target)) {
            this.handleSubmit();
        }
    }
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.setState({clicked: false})
        }
    }
    handleChange(event) {
        this.setState({value: event.target.value});
    }
    handleSubmit() {
        this.props.handleSubmit(this.props.category, this.state.value);
        this.setState({clicked: false});
    }
    handleKey(e) {
        if (e.keyCode === 13) {
            this.handleSubmit(e);
        }
    }
    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }
    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }
    render() {
        var inventoryedit = classNames({
            'InventoryEdit': true,
            'InventoryEditMultiline': this.props.multiline === true,
            'row': this.props.multiline !== true,
            'col': this.props.multiline === true,
            'start': true,
            'relative': true
        })
        var inventoryeditvalue = classNames({
            'InventoryEditValue': true,
            'InventoryEditValueMultiline': this.props.multiline === true,
            'row': true,
            'start': true,
            'relative': true,
            'click': true
        })
        var inventoryeditvaluedisplay = classNames({
            'InventoryEditValueDisplay': true,
            'backendmaintext': true,
            't3': true
        })
        var inventoryeditlabel = classNames({
            'InventoryEditLabel': true,
            'backendmaintext': true,
            't2': true
        })
        var inventoryeditinput = classNames({
            'InventoryEditInput': true,
            'InventoryEditInputMultiline': this.props.multiline === true,
            'backendmaintext': true,
            't3': true
        })
        var inventoryeditbutton = classNames({
            'InventoryEditButton': true,
            'backendrounded': true,
            'backendbutton': true,
            'backendmaintext': true,
            't4': true,
            'click': true
        })
        var display = this.state.clicked === false ? 
            <div className = {inventoryeditvaluedisplay}>
                {this.props.value}
            </div>
        : null
        var input = this.state.clicked === true ? this.props.multiline ? 
            <textarea className = {inventoryeditinput} value = {this.state.value} onChange = {this.handleChange} ref = {input => (this.input = input)} onKeyDown = {(e) => this.handleKey(e)}></textarea> 
            : <input className = {inventoryeditinput} value = {this.state.value} onChange = {this.handleChange} ref = {input => (this.input = input)} onKeyDown = {(e) => this.handleKey(e)}></input>
        : null
        return(
            <div className = {inventoryedit}>
                <div className = {inventoryeditlabel}>
                    {this.props.label}: 
                </div>
                <div className = {inventoryeditvalue} ref={this.wrapperRef} onClick = {(e) => this.handleClick(e)}>
                    {display}
                    {input}
                    <div className = {inventoryeditbutton} ref={this.buttonRef} onClick = {(e) => this.handleClick(e)}>
                        {this.state.clicked === true ? 'Confirm' : 'Edit'}
                    </div>
                </div>
            </div>
        )
    }
}
class BackendMusic extends React.Component {
    constructor(props) {
        super(props);
        this.getInfo = this.getInfo.bind(this);
        this.handleItemClick = this.handleItemClick.bind(this);
        this.handleBackClick = this.handleBackClick.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        this.handleDetailEdit = this.handleDetailEdit.bind(this);
        this.handleReplaceImage = this.handleReplaceImage.bind(this);
        this.state = {
            songs: [],
            imageerror: '',
            clicked: 0
        }
    }
    getInfo() {
        axios.get(url, {
            withCredentials: true,
            params: {
                GETSONGS: ''
            },
            credentials: 'same-origin'
        })
        .then(response => response.data)
        .then((data) => {
            this.setState({
                songs: data
            })
        })
    }
    handleItemClick(id) {
        this.setState({
            clicked: id
        })
    }
    handleBackClick() {
        this.setState({
            clicked: 0
        })
    }
    handleAdd(id) {
        axios.get(url, {
            withCredentials: true,
            params: {
                ADDSONG: '',
            },
            credentials: 'same-origin'
        })
        .then(response => response.data)
        .then((data) => {
            if (data) {
                this.getInfo();
            }
        })
    }
    handleRemove(id) {
        axios.get(url, {
            withCredentials: true,
            params: {
                REMOVESONG: '',
                ID: id
            },
            credentials: 'same-origin'
        })
        .then(response => response.data)
        .then((data) => {
            if (data) {
                this.getInfo();
            }
        })
    }
    handleDetailEdit(id, category, value) {
        axios.get(url, {
            withCredentials: true,
            params: {
                UPDATEMUSICDETAILS: '',
                ID: id,
                CATEGORY: category,
                VALUE: value,
            },
            credentials: 'same-origin'
        })
        .then(response => response.data)
        .then((data) => {
            if (data) {
                this.getInfo();
            }
        })
    }
    handleReplaceImage(file, image) {
        const formData = new FormData();
        formData.set("REPLACEIMAGE", true);
        formData.set("IMAGENAME", image);
        formData.append("IMAGE", file);
        axios({
            method: 'post',
            url: url,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(response => response.data)
        .then((data) => {
            if (data === '') {
                this.getInfo();
            }
            this.setState({imageerror: data})
        })
    }
    componentDidMount() {
        this.getInfo();
    }
    render() {
        var backendmusic = classNames({
            'BackendMusic': true,
            'backendrounded': true,
            'row': true,
            'start': true,
            'relative': true,
            'cover': true,
        })
        var backendmusicitems = classNames({
            'BackendMusicItems': true,
            'row': this.props.windowratio > 1,
            'col': this.props.windowratio < 1,
            'start': true,
            'relative': true,
            'cover': true,
            'click': true,
        })
        var backendmusicbackbutton = classNames({
            'BackendMusicBackButton': true,
            'backendbutton': true,
            'backendrounded': true,
            'backendmaintext': true,
            't4': true,
            'textcenter': true,
            'relative': true,
            'click': true,
            'quick': true,
            'scale': true
        })
        var backbutton = this.state.clicked !== 0 ?
            <div className = {backendmusicbackbutton} onClick = {(e) => this.handleBackClick()}>
                Back
            </div>
        : null
        var items = this.state.songs ? this.state.songs.map((i) => (
                <CSSTransition  key = {i.id}
                                classNames = "fade"
                                timeout = {{ enter: 200, exit: 200 }}>
                    <BackendMusicItem   key = {i.id}
                                        id = {i.id}
                                        image = {i.image}
                                        name = {i.name}
                                        links = {i.links}
                                        clicked = {this.state.clicked}
                                        windowratio = {this.props.windowratio}
                                        imageerror = {this.state.imageerror}
                                        handleClick = {this.handleItemClick}
                                        handleRemove = {this.handleRemove}
                                        handleDetailEdit = {this.handleDetailEdit}
                                        handleReplaceImage = {this.handleReplaceImage}/>
                </CSSTransition>
            ))
        : null
        var add = this.state.songs.length !== 0 ? 
            <BackendMusicItem   key = {(this.state.songs[this.state.songs.length - 1].id + 1)}
                                id = {(this.state.songs[this.state.songs.length - 1].id + 1)}
                                image = {['newpreview0.png']}
                                name = {''}
                                links = {[]}
                                add = {true}
                                clicked = {this.state.clicked}
                                windowratio = {this.props.windowratio}
                                imageerror = {this.state.imageerror}
                                handleClick = {this.handleAdd}
                                handleRemove = {this.handleRemove}
                                handleDetailEdit = {this.handleDetailEdit}
                                handleReplaceImage = {this.handleReplaceImage}/>
        : null
        if (this.props.page !== 'MUSIC') {
            return null;
        }
        else {
            return(
            <div className = {backendmusic}>
                <div className = {backendmusicitems}>
                    <TransitionGroup component = {null}>
                        {items}
                    </TransitionGroup>
                    {add}
                </div>
                {backbutton}
            </div>
            )
        }
    }
}
class BackendMusicItem extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        this.handleReplaceImage = this.handleReplaceImage.bind(this);
        this.handleDetailEdit = this.handleDetailEdit.bind(this);
    }
    handleClick(e) {
        this.props.handleClick(this.props.id);
    }
    handleRemove(e) {
        if (e) {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            e.preventDefault();
        }
        this.props.handleRemove(this.props.id);
    }
    handleDetailEdit(category, value) {
        this.props.handleDetailEdit(this.props.id, category, value);
    }
    handleReplaceImage(e, file, image) {
        this.props.handleReplaceImage(file, image, this.props.id);
    }
    render() {
        var backendmusicitem = classNames({
            'BackendMusicItem': true,
            'BackendMusicItemClicked': this.props.clicked === this.props.id,
            'backendrounded': true,
            'col': this.props.clicked !== this.props.id,
            'row': this.props.clicked === this.props.id,
            'start': true,
            'relative': true,
        })
        var backendmusicitemdisplay = classNames({
            'BackendMusicItemDisplay': true,
        })
        var backendmusiciteminfo = classNames({
            'BackendMusicItemInfo': true,
            'col': this.props.windowratio < 1,
            'row': this.props.windowratio > 1,
            'start': true,
            'relative': true,
        })
        var backendmusicitemimagecontainer = classNames({
            'BackendMusicItemImageContainer': true,
            'backendmusiciteminfocontainer': true,
            'backendrounded': true,
            'col': true,
            'center': this.props.windowratio < 1,
            'start': this.props.windowratio > 1,
            'relative': true,
        })
        var backendmusicitemdetailcontainer = classNames({
            'BackendMusicItemDetailContainer': true,
            'backendmusiciteminfocontainer': true,
            'backendrounded': true,
            'col': true,
            'start': true,
            'relative': true,
        })
        var backendmusicitemlinkcontainer = classNames({
            'BackendMusicItemLinkContainer': true,
            'backendmusiciteminfocontainer': true,
            'backendrounded': true,
            'col': true,
            'start': true,
            'relative': true,
        })
        var backendmusicitemoverlay = classNames({
            'BackendMusicItemOverlay': true,
            'backendrounded': true,
            'cover': true,
            'absolute': true,
            'click': true,
            'quick': true,
        })
        var backendmusicitemremove = classNames({
            'BackendMusicItemRemove': true,
            'mg2': true,
            'absolute': true,
            'click': true,
            'quick': true
        })
        var backendmusicimageerror = classNames({
            'BackendMusicImageError': true,
            'backendmaintext': true,
            't2': true,
            'textcenter': true,
        })
        var overlay = this.props.clicked === 0 ? 
            <div className = {backendmusicitemoverlay} onClick = {(e) => this.handleClick(e)}>
            </div>
        : null
        var image = this.props.clicked === 0 ? 
            <div className = {backendmusicitemdisplay} style = {{backgroundImage: "url(" + process.env.PUBLIC_URL + "/images/" + this.props.image + ".png)"}}>
            </div>
        : null
        var remove = this.props.add !== true && this.props.clicked === 0 ?
        <div className = {backendmusicitemremove} onClick = {(e) => this.handleRemove(e)}>
        </div>
        : null
        var info = this.props.clicked === this.props.id ?
            <div className = {backendmusiciteminfo}>
                <div className = {backendmusicitemimagecontainer}>
                    <BackendMusicImage      windowratio = {this.props.windowratio}
                                            image = {this.props.image}
                                            handleReplaceImage = {this.handleReplaceImage}/>
                    <div className = {backendmusicimageerror}>
                        {this.props.imageerror}
                    </div>
                </div>
                <div className = {backendmusicitemdetailcontainer}>
                    <BackendMusicDetailEdit  value = {this.props.name}
                                    label = {'Name'}
                                    category = {'name'}
                                    handleSubmit = {this.handleDetailEdit}/>
                    <div className = {backendmusicitemlinkcontainer}>
                        <BackendMusicDetailEdit     value = {this.props.links[0].link}
                                                    label = {'Apple Music'}
                                                    category = {'apple'}
                                                    multiline = {true}
                                                    handleSubmit = {this.handleDetailEdit}/>
                        <BackendMusicDetailEdit     value = {this.props.links[1].link}
                                                    label = {'Spotify'}
                                                    category = {'spotify'}
                                                    multiline = {true}
                                                    handleSubmit = {this.handleDetailEdit}/>
                        <BackendMusicDetailEdit     value = {this.props.links[2].link}
                                                    label = {'Soundcloud'}
                                                    category = {'soundcloud'}
                                                    multiline = {true}
                                                    handleSubmit = {this.handleDetailEdit}/>
                        <BackendMusicDetailEdit     value = {this.props.links[3].link}
                                                    label = {'Youtube'}
                                                    category = {'youtube'}
                                                    multiline = {true}
                                                    handleSubmit = {this.handleDetailEdit}/>
                    </div>
                </div>
            </div>
        : null
        if (this.props.clicked !== 0 && this.props.clicked !== this.props.id) {
            return null;
        }
        else {
            return(
                <div className = {backendmusicitem}>
                    {remove}
                    {overlay}
                    {image}
                    {info}
                </div>
            )
        }
    }
}
class BackendMusicDetailEdit extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleKey = this.handleKey.bind(this);
        this.wrapperRef = React.createRef();
        this.buttonRef = React.createRef();
        this.state = {
            clicked: false,
            value: this.props.value
        }
    }
    handleClick(event) {
        if (this.state.clicked === false) {
            this.setState({
                clicked: true
            }, function() {
                this.input.focus();
                this.input.select();
            })
        }
        else if (this.buttonRef && this.buttonRef.current.contains(event.target)) {
            this.handleSubmit();
        }
    }
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.setState({clicked: false})
        }
    }
    handleChange(event) {
        this.setState({value: event.target.value});
    }
    handleSubmit() {
        this.props.handleSubmit(this.props.category, this.state.value);
        this.setState({clicked: false});
    }
    handleKey(e) {
        if (e.keyCode === 13) {
            this.handleSubmit(e);
        }
    }
    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }
    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }
    render() {
        var backendmusicedit = classNames({
            'BackendMusicEdit': true,
            'BackendMusicEditMultiline': this.props.multiline === true,
            'row': this.props.multiline !== true,
            'col': this.props.multiline === true,
            'start': true,
            'relative': true
        })
        var backendmusiceditvalue = classNames({
            'BackendMusicEditValue': true,
            'BackendMusicEditValueMultiline': this.props.multiline === true,
            'row': true,
            'start': true,
            'relative': true,
            'click': true
        })
        var backendmusiceditvaluedisplay = classNames({
            'BackendMusicEditValueDisplay': true,
            'backendmaintext': true,
            't4': true
        })
        var backendmusiceditlabel = classNames({
            'BackendMusicEditLabel': true,
            'backendmaintext': true,
            't3': true
        })
        var backendmusiceditinput = classNames({
            'BackendMusicEditInput': true,
            'BackendMusicEditInputMultiline': this.props.multiline === true,
            'backendmaintext': true,
            't4': true
        })
        var backendmusiceditbutton = classNames({
            'BackendMusicEditButton': true,
            'backendrounded': true,
            'backendbutton': true,
            'backendmaintext': true,
            't4': true,
            'click': true
        })
        var display = this.state.clicked === false ? 
            <div className = {backendmusiceditvaluedisplay}>
                {this.props.value}
            </div>
        : null
        var input = this.state.clicked === true ? this.props.multiline ? 
            <textarea className = {backendmusiceditinput} value = {this.state.value} onChange = {this.handleChange} ref = {input => (this.input = input)} onKeyDown = {(e) => this.handleKey(e)}></textarea> 
            : <input className = {backendmusiceditinput} value = {this.state.value} onChange = {this.handleChange} ref = {input => (this.input = input)} onKeyDown = {(e) => this.handleKey(e)}></input>
        : null
        return(
            <div className = {backendmusicedit}>
                <div className = {backendmusiceditlabel}>
                    {this.props.label}: 
                </div>
                <div className = {backendmusiceditvalue} ref={this.wrapperRef} onClick = {(e) => this.handleClick(e)}>
                    {display}
                    {input}
                    <div className = {backendmusiceditbutton} ref={this.buttonRef} onClick = {(e) => this.handleClick(e)}>
                        {this.state.clicked === true ? 'Confirm' : 'Edit'}
                    </div>
                </div>
            </div>
        )
    }
}
class BackendMusicImage extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.handleHover = this.handleHover.bind(this);
        this.handleReplaceImage = this.handleReplaceImage.bind(this);
        this.handleFile = this.handleFile.bind(this);
        this.input = React.createRef();
        this.state = {
            clicked: 0,
            file: null,
            hover: false,
            uploading: false,
        }
    }
    handleClick(id) {
        this.setState({clicked: id});
    }
    handleHover(value) {
        if (this.state.uploading === false) {
            this.setState({hover: value});
        }
    }
    handleReplaceImage() {
        this.input.click();
        this.setState({uploading: true});
    }
    handleFile(e) {
        e.stopPropagation();
        e.preventDefault();
        var file = e.target.files[0];
        this.setState({
            file: file,
            hover: false,
            uploading: false
        }, function() {
            this.props.handleReplaceImage(e, this.state.file, this.props.image);
        });
    }
    render() {
        var backendmusicimage = classNames({
            'BackendMusicImage': true,
            'col': true,
            'center': true,
            'relative': true,
            'click': true,
        })
        var backendmusicimagereplace = classNames({
            'BackendMusicImageReplace': true,
            'inventoryitemimageoption': true,
            'backendrounded': true,
            'backendbutton': true,
            'backendmaintext': true,
            't3': true,
            'textcenter': true,
            'click': true,
        })
        var backendmusicimageinput = classNames({
            'BackendMusicImageInput': true,
        })
        return (
            <div className = {backendmusicimage} style = {{backgroundImage: "url(" + process.env.PUBLIC_URL + "/images/" + this.props.image + ".png"}} onMouseEnter={() => this.handleHover(true)} onMouseLeave={() => this.handleHover(false)}>
                <div className = {backendmusicimagereplace} onClick = {() => this.handleReplaceImage()}>
                    Replace
                    <input className = {backendmusicimageinput} type="file" name="file" onChange={(e) => this.handleFile(e)} ref = {input => (this.input = input)} />
                </div>
            </div>
        )
    }
}
class Settings extends React.Component {
    constructor(props) {
        super(props);
        this.handleShippingEdit = this.handleShippingEdit.bind(this);
        this.handleCountdownEdit = this.handleCountdownEdit.bind(this);
        this.handleDiscountEdit = this.handleDiscountEdit.bind(this);
        this.handleDiscountRemove = this.handleDiscountRemove.bind(this);
        this.handleDiscountAdd = this.handleDiscountAdd.bind(this);
        this.getDiscounts = this.getDiscounts.bind(this);
        this.exportCSVS = this.exportCSVS.bind(this);
        this.sendShipping = this.sendShipping.bind(this);
    }
    handleShippingEdit(id, type, value) {
        axios.get(url, {
            withCredentials: true,
            params: {
                UPDATESHIPPING: '',
                ID: id,
                TYPE: type,
                VALUE: value,
            },
            credentials: 'same-origin'
        })
        .then(response => response.data)
        .then((data) => {
            if (data) {
                shippingdata = data;
            }
        })
    }
    handleCountdownEdit(date) {
        axios.get(url, {
            withCredentials: true,
            params: {
                UPDATELOCKDATE: '',
                DATE: date,
                OFFSET: new Date().getTimezoneOffset()/60
            },
            credentials: 'same-origin'
        })
        .then(response => response.data)
        .then((data) => {
            if (data) {
                
            }
        })
    }
    handleDiscountEdit(id, code, type, value) {
        axios.get(url, {
            withCredentials: true,
            params: {
                UPDATEDISCOUNT: '',
                ID: id,
                CODE: code,
                TYPE: type,
                VALUE: value,
            },
            credentials: 'same-origin'
        })
        .then(response => response.data)
        .then((data) => {
            if (data) {
                this.getDiscounts();
            }
        })
    }
    handleDiscountRemove(id) {
        axios.get(url, {
            withCredentials: true,
            params: {
                REMOVEDISCOUNT: '',
                ID: id
            },
            credentials: 'same-origin'
        })
        .then(response => response.data)
        .then((data) => {
            if (data) {
                this.getDiscounts();
            }
        })
    }
    handleDiscountAdd() {
        axios.get(url, {
            withCredentials: true,
            params: {
                ADDDISCOUNT: '',
            },
            credentials: 'same-origin'
        })
        .then(response => response.data)
        .then((data) => {
            if (data) {
                this.getDiscounts();
            }
        })
    }
    getDiscounts() {
        axios.get(url, {
            withCredentials: true,
            params: {
                GETDISCOUNTS: '',
            },
            credentials: 'same-origin'
        })
        .then(response => response.data)
        .then((data) => {
            if (data) {
                discounts = data
                this.forceUpdate();
            }
        })
    }
    exportCSVS() {
        axios.get(url, {
            withCredentials: true,
            params: {
                EXPORTCSVS: '',
            },
            credentials: 'same-origin',
            responseType: 'arraybuffer'
        })
        .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data],{type:'application/zip'}));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'F1LTHYWARE Orders.zip');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        });
    }
    sendShipping(file) {
        const formData = new FormData();
        formData.set("SENDSHIPPING", true);
        formData.append("DETAILS", file);
        axios({
            method: 'post',
            url: url,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(response => response.data)
        .then((data) => {
            console.log(data);
        })
    }
    componentDidMount() {
        this.getDiscounts();
    }
    render() {
        var settings = classNames({
            'Settings': true,
            'backendrounded': true,
            'col': true,
            'start': true,
            'relative': true,
            'cover': true,
        })
        var settingscontainer = classNames({
            'SettingsContainer': true,
            'col': true,
            'start': true,
            'relative': true,
            'cover': true,
            'click': true,
        })
        var shippingedit = classNames({
            'ShippingEdit': true,
            'backendrounded': true,
            'col': true,
            'start': true,
            'relative': true,
        })
        var shippingedits = classNames({
            'ShippingEdits': true,
            'row': true,
            'start': true,
            'relative': true,
        })
        var shippingeditlabel = classNames({
            'ShippingEditLabel': true,
            'backendmaintext': true,
            't2': true
        })
        var discountsedit = classNames({
            'DiscountsEdit': true,
            'backendrounded': true,
            'col': true,
            'start': true,
            'relative': true,
        })
        var discountsedits = classNames({
            'DiscountsEdits': true,
            'col': true,
            'start': true,
            'relative': true,
        })
        var discountseditlabel = classNames({
            'DiscountsEditLabel': true,
            'backendmaintext': true,
            't2': true
        })
        var discountsadd = classNames({
            'DiscountsAdd': true,
            'click': true
        })
        var csvexport = classNames({
            'CSVExport': true,
            'backendrounded': true,
            'col': true,
            'start': true,
            'relative': true,
        })
        var csvexportlabel = classNames({
            'CSVExportLabel': true,
            'backendmaintext': true,
            't2': true
        })
        var csvexportbutton = classNames({
            'CSVExportButton': true,
            'backendrounded': true,
            'backendbutton': true,
            'backendmaintext': true,
            't2': true,
            'click': true
        })
        var shippinglist = shippingdata ? 
        <div className = {shippingedits}>
            {shippingdata.map((i, index) => (
                <ShippingEdit   key = {index}
                                id = {i.id}
                                title = {i.location}
                                windowratio = {this.props.windowratio}
                                handleSubmit = {this.handleShippingEdit}/>))}
        </div>
        : null
        var discountslist = 
            (discounts ? discounts.map((i, index) => (
                <CSSTransition
                key = {i.id}
                classNames = "fade"
                timeout = {{ enter: 200, exit: 200 }}>
                    <DiscountEdit   index = {index}
                                    id = {i.id}
                                    title = {i.location}
                                    windowratio = {this.props.windowratio}
                                    handleSubmit = {this.handleDiscountEdit}
                                    handleRemove = {this.handleDiscountRemove}/>
                </CSSTransition>
        )):null)
        if (this.props.page !== 'SETTINGS') {
            return null;
        }
        else {
            return(
            <div className = {settings}>
                <div className = {settingscontainer}>
                    <div className = {shippingedit}>
                        <div className = {shippingeditlabel}>
                            Shipping
                        </div>
                        {shippinglist}
                    </div>
                    <CountdownEdit  lockdate = {this.props.lockdate}
                                    handleSubmit = {this.handleCountdownEdit}/>
                    <div className = {csvexport}>
                        <div className = {csvexportlabel}>
                            Export CSV
                        </div>
                        <div className = {csvexportbutton} onClick = {() => this.exportCSVS()}>
                            Export
                        </div>
                    </div>
                    <SendShipping   sendShipping = {this.sendShipping}
                    />
                    <div className = {discountsedit}>
                        <div className = {discountseditlabel}>
                            Discounts
                        </div>
                        <div className = {discountsedits}>
                            <TransitionGroup component={null}>
                                {discountslist}
                            </TransitionGroup>
                        </div>
                        <div className = {discountsadd} onClick = {() => this.handleDiscountAdd()}>
                        </div>
                    </div>
                </div>
            </div>
            )
        }
    }
}
class SendShipping extends React.Component {
    constructor(props) {
        super(props);
        this.handleFile = this.handleFile.bind(this);
        this.sendShipping = this.sendShipping.bind(this);
        this.input = React.createRef();
        this.state = {
            file: null,
            uploading: false,
            text: "Upload"
        }
    }
    sendShipping() {
        this.input.click();
        this.setState({uploading: true});
    }
    handleFile(e) {
        e.stopPropagation();
        e.preventDefault();
        var file = e.target.files[0];
        this.setState({
            file: file,
            uploading: false
        }, function() {
            this.props.sendShipping(this.state.file);
        });
    }
    render() {
        var sendshipping = classNames({
            'SendShipping': true,
            'backendrounded': true,
            'col': true,
            'start': true,
            'relative': true,
        })
        var sendshippinglabel = classNames({
            'SendShippingLabel': true,
            'backendmaintext': true,
            't2': true
        })
        var sendshippingbutton = classNames({
            'SendShippingButton': true,
            'backendrounded': true,
            'backendbutton': true,
            'backendmaintext': true,
            't2': true,
            'click': true
        })
        var sendshippinginput = classNames({
            'SendShippingInput': true,
        })
        return(
            <div className = {sendshipping}>
                <div className = {sendshippinglabel}>
                    Send Shipping Emails
                </div>
                <div className = {sendshippingbutton} onClick = {(e) => this.sendShipping()}>
                    {this.state.text}
                    <input className = {sendshippinginput} type="file" name="file" onChange={(e) => this.handleFile(e)} ref = {input => (this.input = input)} />
                </div>
            </div>
        )
    }
}
class ShippingEdit extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleDropdownChange = this.handleDropdownChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleKey = this.handleKey.bind(this);
        this.wrapperRef = React.createRef();
        this.buttonRef = React.createRef();
        this.state = {
            clicked: false,
            type: shippingdata[this.props.id - 1].type,
            value: shippingdata[this.props.id - 1].value
        }
    }
    handleClick(event) {
        if (this.state.clicked === false) {
            this.setState({
                clicked: true
            }, function() {
                this.input.focus();
                this.input.select();
            })
        }
        else if (this.buttonRef && this.buttonRef.current.contains(event.target)) {
            this.handleSubmit();
        }
    }
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.setState({clicked: false})
        }
    }
    handleDropdownChange(type) {
        this.setState({type: type}, function() {
            this.handleSubmit();
        });
    }
    handleChange(event) {
        this.setState({value: event.target.value});
    }
    handleSubmit() {
        this.props.handleSubmit(this.props.id, this.state.type, this.state.value);
        this.setState({clicked: false});
    }
    handleKey(e) {
        if (e.keyCode === 13) {
            this.handleSubmit(e);
        }
    }
    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }
    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }
    render() {
        var shippingedititem = classNames({
            'ShippingEditItem': true,
            'col': true,
            'start': true,
            'relative': true,
        })
        var shippingeditvalue = classNames({
            'ShippingEditValue': true,
            'row': true,
            'start': true,
            'relative': true,
            'click': true
        })
        var shippingeditvaluedisplay = classNames({
            'ShippingEditValueDisplay': true,
            'backendmaintext': true,
            't2': true
        })
        var shippingeditinput = classNames({
            'ShippingEditInput': true,
            'backendmaintext': true,
            't2': true
        })
        var shippingeditbutton = classNames({
            'ShippingEditButton': true,
            'backendrounded': true,
            'backendbutton': true,
            'backendmaintext': true,
            't4': true,
            'click': true
        })
        var shippingedittitle = classNames({
            'ShippingEditTitle': true,
            'backendmaintext': true,
            't3': true
        })
        var valuedisplay = this.state.clicked === false ? 
            <div className = {shippingeditvaluedisplay}>
                {this.state.type === 'Percentage' ? '' : '$'}{this.state.value}{this.state.type === 'Percentage' ? '%' : ''}
            </div>
        : null
        var valueinput = this.state.clicked === true ? 
            <input className = {shippingeditinput} value = {this.state.value} onChange = {this.handleChange} ref = {input => (this.input = input)} onKeyDown = {(e) => this.handleKey(e)}></input>
        : null
        return(
            <div className = {shippingedititem}>
                <div className = {shippingedittitle}>
                    {this.props.title}
                </div>
                <div className = {shippingeditvalue}>
                    <BackendDropdown    value = {this.state.type}
                                        options = {['Percentage', 'Flat']}
                                        handleValueChange = {this.handleDropdownChange}/>
                </div>
                <div className = {shippingeditvalue} ref={this.wrapperRef} onClick = {(e) => this.handleClick(e)}>
                    {valuedisplay}
                    {valueinput}
                    <div className = {shippingeditbutton} ref={this.buttonRef} onClick = {(e) => this.handleClick(e)}>
                        {this.state.clicked === true ? 'Confirm' : 'Edit'}
                    </div>
                </div>
            </div>
        )
    }
}
class DiscountEdit extends React.Component {
    constructor(props) {
        super(props);
        this.handleValueClick = this.handleValueClick.bind(this);
        this.handleCodeClick = this.handleCodeClick.bind(this);
        this.handleValueClickOutside = this.handleValueClickOutside.bind(this);
        this.handleCodeClickOutside = this.handleCodeClickOutside.bind(this);
        this.handleDropdownChange = this.handleDropdownChange.bind(this);
        this.handleCodeChange = this.handleCodeChange.bind(this);
        this.handleValueChange = this.handleValueChange.bind(this);
        this.handleKey = this.handleKey.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        this.valueRef = React.createRef();
        this.codeRef = React.createRef();
        this.valueButtonRef = React.createRef();
        this.codeButtonRef = React.createRef();
        this.state = {
            valueclicked: false,
            codeclicked: false,
            code: discounts[this.props.index].code,
            type: discounts[this.props.index].type,
            value: discounts[this.props.index].value
        }
    }
    handleValueClick(event) {
        if (this.state.valueclicked === false) {
            this.setState({
                valueclicked: true
            }, function() {
                this.valueInput.focus();
                this.valueInput.select();
            })
        }
        else if (this.valueButtonRef && this.valueButtonRef.current.contains(event.target)) {
            this.handleSubmit();
        }
    }
    handleCodeClick(event) {
        if (this.state.codeclicked === false) {
            this.setState({
                codeclicked: true
            }, function() {
                this.codeInput.focus();
                this.codeInput.select();
            })
        }
        else if (this.codeButtonRef && this.codeButtonRef.current.contains(event.target)) {
            this.handleSubmit();
        }
    }
    handleValueClickOutside(event) {
        if (this.valueRef && !this.valueRef.current.contains(event.target)) {
            this.setState({valueclicked: false})
        }
    }
    handleCodeClickOutside(event) {
        if (this.codeRef && !this.codeRef.current.contains(event.target)) {
            this.setState({codeclicked: false})
        }
    }
    handleDropdownChange(type) {
        this.setState({type: type}, function() {
            this.handleSubmit();
        });
    }
    handleValueChange(event) {
        this.setState({value: event.target.value});
    }
    handleCodeChange(event) {
        this.setState({code: event.target.value});
    }
    handleSubmit() {
        this.props.handleSubmit(this.props.id, this.state.code, this.state.type, this.state.value);
        this.setState({
            valueclicked: false,
            codeclicked: false
        });
    }
    handleRemove() {
        this.props.handleRemove(this.props.id);
    }
    handleKey(e) {
        if (e.keyCode === 13) {
            this.handleSubmit(e);
        }
    }
    componentDidMount() {
        document.addEventListener("mousedown", this.handleValueClickOutside);
        document.addEventListener("mousedown", this.handleCodeClickOutside);
    }
    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleValueClickOutside);
        document.removeEventListener("mousedown", this.handleCodeClickOutside);
    }
    render() {
        var discountedititem = classNames({
            'DiscountEditItem': true,
            'backendrounded': true,
            'col': true,
            'start': true,
            'relative': true,
        })
        var discounteditvalue = classNames({
            'DiscountEditValue': true,
            'row': true,
            'start': true,
            'relative': true,
            'click': true
        })
        var discounteditvaluedisplay = classNames({
            'DiscountEditValueDisplay': true,
            'backendmaintext': true,
            't2': true
        })
        var discounteditinput = classNames({
            'DiscountEditInput': true,
            'backendmaintext': true,
            't2': true
        })
        var discounteditbutton = classNames({
            'DiscountEditButton': true,
            'backendrounded': true,
            'backendbutton': true,
            'backendmaintext': true,
            't4': true,
            'click': true
        })
        var discountedittitle = classNames({
            'DiscountEditTitle': true,
            'backendmaintext': true,
            't3': true
        })
        var discountremove = classNames({
            'DiscountRemove': true,
            'mg2': true,
            'absolute': true,
            'click': true,
            'quick': true
        })
        var valuedisplay = this.state.valueclicked === false ? 
            <div className = {discounteditvaluedisplay}>
                {this.state.type === 'Percentage' ? '' : '$'}{this.state.value}{this.state.type === 'Percentage' ? '%' : ''}
            </div>
        : null
        var valueinput = this.state.valueclicked === true ? 
            <input className = {discounteditinput} value = {this.state.value} onChange = {this.handleValueChange} ref = {valueInput => (this.valueInput = valueInput)} onKeyDown = {(e) => this.handleKey(e)}></input>
        : null
        var codedisplay = this.state.codeclicked === false ? 
            <div className = {discounteditvaluedisplay}>
                {this.state.code}
            </div>
        : null
        var codeinput = this.state.codeclicked === true ? 
            <input className = {discounteditinput} value = {this.state.code} onChange = {this.handleCodeChange} ref = {codeInput => (this.codeInput = codeInput)} onKeyDown = {(e) => this.handleKey(e)}></input>
        : null
        return(
            <div className = {discountedititem}>
                <div className = {discountremove} onClick = {() => this.handleRemove()}>
                </div>
                <div className = {discounteditvalue} ref={this.codeRef} onClick = {(e) => this.handleCodeClick(e)}>
                    {codedisplay}
                    {codeinput}
                    <div className = {discounteditbutton} ref={this.codeButtonRef} onClick = {(e) => this.handleCodeClick(e)}>
                        {this.state.codeclicked === true ? 'Confirm' : 'Edit'}
                    </div>
                </div>
                <div className = {discountedittitle}>
                    {this.props.title}
                </div>
                <div className = {discounteditvalue}>
                    <BackendDropdown    value = {this.state.type}
                                        options = {['Percentage', 'Flat']}
                                        handleValueChange = {this.handleDropdownChange}/>
                </div>
                <div className = {discounteditvalue} ref={this.valueRef} onClick = {(e) => this.handleValueClick(e)}>
                    {valuedisplay}
                    {valueinput}
                    <div className = {discounteditbutton} ref={this.valueButtonRef} onClick = {(e) => this.handleValueClick(e)}>
                        {this.state.valueclicked === true ? 'Confirm' : 'Edit'}
                    </div>
                </div>
            </div>
        )
    }
}
class CountdownEdit extends React.Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.state = {
            date: this.props.lockdate,
        }
    }
    onChange(date) {
        var useroffset = date.getTimezoneOffset()*60*1000;
        var newdate = new Date((date - useroffset)).toISOString().slice(0, 19).replace('T', ' ');
        this.setState({
            date: date
        })
        this.props.handleSubmit(newdate);
    }
    render() {
        var countdownedit = classNames({
            'CountdownEdit': true,
            'backendrounded': true,
            'col': true,
            'start': true,
            'relative': true,
            'click': true
        })
        var countdowneditlabel = classNames({
            'CountdownEditLabel': true,
            'backendmaintext': true,
            't2': true
        })
        return(
            <div className = {countdownedit}>
                <div className = {countdowneditlabel}>
                    Countdown End
                </div>
                <DateTimePicker onChange={this.onChange} value={this.state.date} disableClock = {true} calendarIcon = {null} clearIcon = {null}/>
            </div>
        )
    }
}
class Login extends React.Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.passwordChange = this.passwordChange.bind(this);
        this.state = {
            password: '',
            passworderror: '',
            loginerror: ''
        }
    }
    handleSubmit() {
        if (this.state.password !== '') {
            axios.get(url, {
                withCredentials: true,
                params: {
                    LOGIN: '',
                    PASSWORD: this.state.password
                },
                credentials: 'same-origin',
            })
            .then(response => response.data)
            .then((data) => {
                if (data === '') {
                    this.props.handleLoginChange(true);
                }
                this.setState({loginerror: data});
            })
        }
    }
    passwordChange(event) {
        this.setState({password: event.target.value});
    }
    render() {
        var loginform = classNames({
            'LoginForm': true,
            'col': true,
            'center': true,
            'quick': true
        })
        var loginlogo = classNames({
            'LoginLogo': true
        })
        var loginsubmitbutton = classNames({
            'LoginSubmitButton': true,
            'backendrounded': true,
            'backendbutton': true,
            'backendmaintext': true,
            'click': true,
            'quick': true,
            'scale': true,
            't2': true,
        })
        var loginformerror = classNames({
            'LoginFormError': true,
            'backendmaintext': true,
            't2': true,
        })
        return (
            <div className = {loginform}>
                <div className = {loginlogo}>
                </div>
                <LoginFormItem    label = 'PASSWORD'
                                    value = {this.state.password}
                                    confidential = {true}
                                    handleValueChange = {this.passwordChange}
                                    error = {this.state.passworderror}/>
                <div className = {loginsubmitbutton} onClick = {this.handleSubmit}>
                    LOGIN
                </div>
                <div className = {loginformerror}>
                    {this.state.loginerror}
                </div>
            </div>
        )
    }
}
class LoginFormItem extends React.Component {
    constructor(props) {
        super(props);
        this.valueChange = this.valueChange.bind(this);
    }
    valueChange(value) {
        this.props.handleValueChange(value);
    }
    render() {
        var loginforminput = classNames({
            'LoginFormInput': true,
            'backendmaintext': true,
            't1': true,
            'click': true,
        })
        var loginformvalue = classNames({
            'LoginFormValue': true,
            'col': true,
            'start': true,
            'relative': true,
        })
        var loginformerror = classNames({
            'LoginFormError': true,
            'backendmaintext': true,
            't2': true,
            'mg2': true
        })
        var loginformitem = classNames({
            'LoginFormItem': true,
            'row': true,
            'start': true,
            'relative': true,
        })
        var loginformlabel = classNames({
            'LoginFormLabel': true,
            'backendmaintext': true,
            't1': true,
        })
        return (
            <div className = {loginformitem}>
                <div className = {loginformlabel}>{this.props.label}</div>
                <div className = {loginformvalue}>
                    <input type = {this.props.confidential ? 'password' : 'text'} className = {loginforminput} value = {this.props.username} onChange = {this.valueChange}></input>
                    <div className = {loginformerror}>
                        {this.props.error}
                    </div>
                </div>
            </div>
        )
    }
}
class Slider extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange() {
        if (this.props.value === true) {
            this.props.handleChange(false);
        }
        else {
            this.props.handleChange(true);
        }
    }
    render() {
        var slidertoggle = classNames ({
            'SliderToggle': true,
            'col': true,
            'center': true,
            'relative': true
        })
        var sliderinput = classNames ({
            'SliderInput': true,
        })
        var slider = classNames({
            'Slider': true,
            'SliderChecked': this.props.value === true,
            'backendrounded': true,
            'col': true,
            'start': this.props.value === false,
            'end': this.props.value === true,
            'relative': true,
            'click': true,
            'quick': true,
            'absolute': true
        })
        var sliderbutton = classNames({
            'SliderButton': true,
            'backendrounded': true,
            'click': true,
            'quick': true,
            'absolute': true
        })
        return(
            <label className = {slidertoggle}>
                <input className = {sliderinput} value = {this.props.value} type="checkbox" onChange = {(e) => this.handleChange()} defaultChecked={this.props.value}>
                </input>
                <div className = {slider}>
                    <div className = {sliderbutton}>
                    </div>
                </div>
            </label>
        )
    }
}
class Site extends React.Component {
    constructor(props) {
        super(props);
        this.backendRequest = '';
        this.getLockDate = this.getLockDate.bind(this);
        this.getShipping = this.getShipping.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleVFX = this.handleVFX.bind(this);
        this.handleMute = this.handleMute.bind(this);
        this.handleLoad = this.handleLoad.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.currentdate = new Date();
        this.lockdate = null;
        this.state = {
            page: 'HOME',
            muted: false,
            vfx: true,
            windowratio: 1,
            loading: true,
        };
    }
    getLockDate() {
        axios.get(url, {
            withCredentials: true,
            params: {
                GETLOCKDATE: ''
            },
            credentials: 'same-origin'
        })
        .then(response => response.data)
        .then((data) => {
            let dateTimeParts = data.date.split(/[- :]/);
            var offset = data.offset - (new Date().getTimezoneOffset()/60);
            dateTimeParts[1] = (parseFloat(dateTimeParts[1]) - 1).toString();
            dateTimeParts[3] = (parseFloat(dateTimeParts[3]) + offset).toString();
            this.lockdate = new Date(...dateTimeParts);
            this.forceUpdate();
        })
    }
    getShipping() {
        axios.get(url, {
            withCredentials: true,
            params: {
                GETSHIPPING: ''
            },
            credentials: 'same-origin'
        })
        .then(response => response.data)
        .then((data) => {
            shippingdata = data;
        })
    }
    handlePageChange(newPage) {
        this.setState({
            page: newPage
        });
    }
    handleLoginChange(value) {
        window.location.reload(false);
    }
    handleVFX(bool) {
        this.setState({
            vfx: bool
        })
    }
    handleMute(bool) {
        this.setState({
            muted: bool
        })
    }
    handleLoad() {
        this.setState({
            loading: false
        })
    }
    componentDidMount() {
        window.addEventListener('resize', this.updateWindowDimensions);
        window.addEventListener('load', this.handleLoad);
        this.updateWindowDimensions();
        let params = new URLSearchParams(window.location.search);
        this.backendRequest = params.get("manage");
        if (this.backendRequest !== null) {
            this.handlePageChange('ORDERS');
        }
        this.getShipping();
        this.getLockDate();
        if (isSafari && !isMobile) {
            this.setState({
                muted: true,
            })
        }
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
        window.removeEventListener('load', this.handleLoad);
    }
    updateWindowDimensions() {
        this.setState({ windowratio: window.innerWidth/window.innerHeight });
    }
    render() {
        var app = classNames({
            'App': true,
            'col': true,
            'center': true,
        })
        var fader = this.backendRequest === null ? 
            <Fader loading = {this.state.loading}/> : null
        var audio = this.backendRequest === null && (this.lockdate !== null ? this.lockdate.getTime() < this.currentdate.getTime() : false) ? 
            <Audio  page = {this.state.page}
                    muted = {this.state.muted}
                    handleMute = {this.handleMute}/> : null
        var instructions = this.backendRequest === null && (this.lockdate !== null ? this.lockdate.getTime() < this.currentdate.getTime() : false) ? 
            <Instructions   loading = {this.state.loading}
                            vfx = {this.state.vfx}
                            page = {this.state.page}/> : null
        var background = this.backendRequest === null && (this.lockdate !== null ? this.lockdate.getTime() < this.currentdate.getTime() : false) ? 
            <Background vfx = {this.state.vfx}
                        page = {this.state.page}/> : null
        var screen = this.backendRequest === null && (this.lockdate !== null ? this.lockdate.getTime() < this.currentdate.getTime() : false) ?
            <Screen windowratio = {this.state.windowratio}
                    vfx = {this.state.vfx}
                    page = {this.state.page}
                    muted = {this.state.muted}
                    handlePageChange = {this.handlePageChange}/> : null
        var backend = this.backendRequest !== null ?
            <Backend    windowratio = {this.state.windowratio}
                        vfx = {this.state.vfx}
                        page = {this.state.page}
                        lockdate = {this.lockdate}
                        handlePageChange = {this.handlePageChange}
                        handleLoginChange = {this.handleLoginChange}/> : null
        var countdown = this.backendRequest === null && (this.lockdate !== null ? this.lockdate.getTime() > this.currentdate.getTime() : false) ?
            <Countdown  lockdate = {this.lockdate}/>
        : null
        return (
            <div className = {app}>
                {fader}
                {audio}
                {instructions}
                {background}
                {screen}
                {countdown}
                {backend}
            </div>
        )
  }
}


ReactDOM.render(<Site />, document.getElementById('root'));